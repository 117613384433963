import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const createBusiness = "Crear tu empresa ahora";

const es = {
  title: "Simple Commerce",
  createBusiness,
  global: {
    LandingNavbar: {
      dashboard: "Dashboard",
      start: "Iniciar Sesión",
      create: "Crear mi empresa",
      howWork: "Cómo funciona",
      prices: "Precios",
      alliances: "Alianzas"
    }
  },
  home: {
    metaTitle: TITLE,
    metaDescription:
      "Crea tu empresa con nosotros y recibe la asesoría que necesitas"
  },
  Business: {
    question: "¿Tienes una cuenta?",
    singIn: "Inicia sesión"
  },
  landingHome: {
    VideoSection: {
      title: "Crea tu empresa. Nosotros nos encargamos del papeleo.",
      description:
        "Nosotros creamos tu empresa y nos encargamos de todos los trámites.",
      button: createBusiness
    },
    InstructionSection: {
      firstTitle:
        "Llena los datos detallados de tu empresa, verifica y relájate",
      firstDescription:
        "Arranca tu Negocio es una plataforma que te permite la creación de tu empresa sin tropiezos, con la guía y asesoría de expertos. Tener una persona jurídica, es decir una empresa, permite que tu negocio crezca mientras tú y tu patrimonio están protegidos.",
      firstDescriptionParagraph:
        " Da mayor credibilidad y seriedad en tus actividades económicas y tienes beneficios exclusivos en materia tributaria, mercantil y laboral.",
      secondTitle: "Nos encargamos de todo",
      secondDescription:
        "Crear una empresa es una tarea compleja, por lo general requieres de abogados expertos societario para poder hacerlo sin errores, lo cual implica gastos de honorarios profesionales, nosotros te guiamos paso a paso a un costo reducido para que crees tú empresa como un profesional.",
      secondDescriptionParagraph:
        " Te ayudamos en el paso a paso para que tu negocio cumpla con todos los requisitos establecidos en la ley.",
      secondDescriptionItems: {
        itemOne: "Más rápido",
        itemTwo: "No incurres en gastos excesivos",
        itemThree: "Asesoramiento acorde tus necesidades"
      },
      thirdTitle:
        "Contrata nuestros servicios con las principales plataformas bancarias",
      thirdDescription:
        "¡En un solo paso!, como te dijimos, nos encargamos de todo.",
      thirdDescriptionParagraph:
        " Se aceptan Visa y MasterCard (débito o crédito), puedes diferir a 3 o 6 meses con intereses solo con las siguientes tarjetas:",
      thirdDescriptionItems: {
        item1: "Produbanco (Visa - MasterCard)",
        item2: "Amazonas (Visa)",
        item3: "Bolivariano (MasterCard)",
        item4: "Mutualista Pichincha (MasterCard)",
        item5: "Internacional (Visa - MasterCard)",
        item6: "Mutualista Azuay (Visa)",
        item7: "Machala (Visa - MasterCard)",
        item8: "Solidario (Visa)"
      }
    },
    ProcessStep: {
      title: "¡Todo en nuestra plataforma!",
      subtitle:
        "Crea una cuenta, registra el nombre de tu negocio y la actividad económica que corresponda. Completa el formulario de pago.",
      firstStepTitle: "Completa todos los detalles de la empresa",
      firstStepInfo:
        "Define el nombre de tu empresa, o coloca un nombre provisional que podrás modificar después. Ingresa datos adicionales y continúa. Nombre de tu empresa, actividad económica, nómina de accionistas. ¡No te preocupes! Suena mucho, pero lo haremos juntos, de una manera fácil y guiada.",
      secondStepTitle: "Espera mientras nos encargamos",
      secondStepInfo:
        "En poco tiempo tendrás: Tu empresa aprobada, su registro único de contribuyentes, nombramientos de representantes legales y cuenta. ",
      thirdStepTitle: "¡Listo!",
      thirdStepInfo: "Comienza a facturar y a hacer que crezca tu negocio ",
      button: createBusiness,
      secondaryButton: "Ver precios"
    }
  },
  DocumentEmpty: {
    title: "No tienes documentos disponibles",
    subtitle: "Los documentos se mostrarán aquí una vez que estén revisados."
  },
  AdminDocument: {
    successMessage: "Los archivos se han subido correctamente.",
    successError: "Ocurrio un error. Por favor vuelva a intentarlo.",
    formation: "Formación",
    documents: "Documentos",
    sendDocuments: "Enviar documentos",
    taxes: "Impuestos",
    uploadFile: "Haz click o arrastra el archivo a esta área para cargarlo",
    uploadFileText: "Puedes arrastrar uno o varios archivos"
  },
  landingsProducts: {
    productFeatures: [
      {
        name: "Tramitación para la reserva del nombre."
      },
      {
        name: "Contrato constitutivo"
      },
      {
        name: "Aprobación documentación en la Supercias completar denominaciones"
      },

      {
        name: "Nos encargamos de tramitar su RUC "
      }
    ]
  },
  productsSearch: {
    metaTitle: "Descubre"
  },
  loyalty: {
    metaTitle: "Lealtad"
  },
  coupons: {
    metaTitle: "Cupones",
    detailTitle: "Cupones"
  },
  profile: {
    metaTitle: "Perfil",
    metaDesciption: "A continuación podrás editar los datos de tu perfil.",
    discart: "Descartar",
    save: "Guardar"
  },
  CapitalForm: {
    Principal: {
      title: "Capital",
      subtitle:
        "En esta sección vas a diseñar todo el esquema financiero de tu compañía",
      shareholderQuestion: "¿Necesitas añadir o editar un accionista?",
      shareholderAction: " Visita la página Accionistas",
      addShareholders: "Añadir accionistas",
      shareholdersSubtitle:
        "Para completar este paseo debe añadir al menos un accionista"
    },
    financialScheme: {
      title: "Capital de tu compañía.",
      label: "Capital Suscrito"
    },
    selectLabel: "Plazo a pagar el capital",
    blockTitle: "Define el porcentaje de acciones"
  },
  ShareholdersHelp: {
    title: "Accionistas no actualizados"
  },
  navigation: {
    shopping: "Compras",
    discover: "Descubre",
    loyalty: "Lealtad",
    coupons: "Cupones",
    profile: "Usuario"
  },
  catalogues: {
    delivery: "Entrega",
    pickup: "Retiro",
    other: "Otro"
  },
  common: {
    preferences: "Preferencias",
    workflows: "Modalidad de compra",
    apply: "Aplicar",
    buyingIn: "Comprando en",
    catalogue: "Catálogo"
  },
  sidebar: {
    itemsCreateCompany: [
      { name: "Información de la empresa", progress: 1 },
      { name: "Información personal", progress: 2 },
      { name: "Resumen y pago", progress: 3 }
    ],
    success: "Completado"
  },
  createCompany: {
    titleBusinessName: "Iniciemos, ponle un nombre a tu empresa",
    subtitleBusinessName:
      "Puedes utilizar un nombre provisional, luego podrás modificarlo y añadir otras alternativas",
    placeholderBusinessName: "Nombre de la empresa",
    continue: "Continuar",
    optionEnter: "O presiona ENTER",
    titleBusinessLocation: "¿En dónde estará ubicada tu empresa?",
    subtitleBusinessLocation:
      "Selecciona el país y la ciudad en dónde estará ubicada tu empresa",
    selectCity: "Selecciona una ciudad",
    selectCountry: "Selecciona un país"
  },
  Logo: {
    title: "Arranca tu negocio"
  },
  WelcomeModal: {
    Welcome: "Bienvenido a "
  },
  Sidebar: {
    dashboard: "Dashboard",
    documents: "Documentos",
    partners: "Partners",
    yourBusiness: "Tus empresas",
    processing: "Procesando",
    success: "Completado",
    newBusiness: "Nueva empresa",
    help: "Ayuda",
    terms: "Términos",
    coupon: "Cupones"
  },
  form: {
    metaTitle: "Datos de perfil",
    addPhoto: "Agregar foto",
    passwordAuth: "Contraseña y autenticación",
    changePass: "Cambiar contraseña",
    forgotPass: "Olvidé mi contraseña",
    actualPass: "Contraseña actual",
    newPass: "Contraseña nueva",
    newPassConfirm: "Confirmar contraseña nueva",
    userName: "Nombre",
    lastName: "Apellido",
    email: "Correo",
    phone: "Teléfono",
    cellphone: "Celular"
  },
  CheckoutStatus: {
    title: " Revisando que todo esté en orden",
    text: "Estamos verificando tu pago, esto no demorará mucho",
    errorTitle: " Hubo un problema al verificar el pago",
    errorButton: "Volver a intentarlo",
    errorBack: "Regresar",
    emptyTitle: " Aún no has agregado productos en tu carrito",
    emptyDescription: "Explora nuestro menú y ¡Agrega productos!",
    emptyButton: "Explorar menú"
  },
  loading: {
    title: "Revisando que todo este en orden",
    subtitle: "Estamos verificando tu pago, esto no demorara mucho"
  },
  mailConfirmation: {
    title: "Hemos enviado un correo a",
    titleResend: "Reenviamos el correo a ",
    mailFirstPhrase:
      "Haz click en el link que enviamos a tu correo para ingresar a tu cuenta",
    mailFirstPhraseResend:
      "Haz click en el link que enviamos a tu correo para ingresar a tu cuenta.",
    mailSecondPhrase: "para ingresar a tu cuenta",
    mailSecondPhraseResend:
      " Si no has recibido nada a tu correo revisa tu bandeja de SPAM, o contáctate con soporte.arrancatunegocio@gmail.com",
    subtitle:
      "Si no has recibido nada a tu correo o a tu bandeja de SPAM o puedes ",
    link: "volver a solicitar el correo ",
    postOrderlegend: "Hemos enviado un correo a",
    postOrdersubtitile:
      "Haz click en el link que enviamos a tu correo para ingresar a tu cuenta"
  },
  Login: {
    Title: "",
    MailTitle: "Correo",
    Button: "Ingresar"
  },
  SignIn: {
    createBusiness: "Crear una empresa",
    title: "Hola, ingresa a tu cuenta",
    description:
      "Ingresa a tu cuenta con tu correo de Gmail, cuenta de Facebook o con tu correo."
  },
  Footer: {
    knowUs: "Conócenos",
    contactUs: "Contáctanos",
    workWithUs: "Trabaja con nosotros",
    terms: "Términos",
    help: "Ayuda"
  },
  GeneralInfoForm: {
    title: "Información general",
    subtitle:
      "Agrega los datos básicos de tu empresa, como actividades económicas, contacto, ubicación",
    economicActivity: "Actividad Económica",
    descriptionActivity:
      "Ingresa una descripción breve sobre la actividad económica que desempeñaras",
    address: "Dirección",
    country: "País",
    city: "Selecciona una ciudad",
    street: "Calle principal",
    addressNumber: "Nro del domicilio",
    secondaryStreet: "Intersección",
    example: "Ej: Junto al parque",
    question: "¿Cuántas oficinas o sucursales tendrás?",
    branches: "Cantidad de sucursales",
    reference: "Referencia",
    mail: "Correo",
    contact: "Datos de contacto",
    fullName: "Nombre Completo",
    phone: "Teléfono convencional",
    cellPhone: "Celular",
    state: "Provincia",
    parish: "Parroquia",
    defData: "Usar datos por defecto del usuario",
    description: "Descripcion"
  },
  SelectBusiness: {
    title: "Selecciona una empresa para ingresar",
    new: "Crear nueva empresa"
  },
  BusinessNamePage: {
    title: "Reserva de nombre (razón social)",
    subtitle:
      "Escribe 3 opciones de nombre para tu empresa, recuerda incluir una palabra única e irrepetible.",
    legalNameTitle: "Nombre comercial",
    legalNamelabel: "Nombre comercial",
    legalNamePlaceholder: "Ingresa el nombre comercial",
    commercialName: "Razón social o denominación",
    option: "Opcion ",
    commercialNameLabel: " de razón social  ",
    subLabel:
      "Es el nombre con el que tus clientes y proveedores te reconocerán.",
    knowMore: "Conoce más",
    addNew: "Añadir otras alternativas de razón social",
    chooseExpresion: "Escoge una expresión particular",
    repetitionError: "ALERTA: Evita repetir las sugerencias de nombre",
    commonWorld:
      "ALERTA: Intenta no repetir palabras entre las opciones de nombre",
    subLabel2:
      "Es el nombre legal y se utilizará para toda la documentación de la empresa.",
    suggestions: "Sugerencias",
    processing: "Procesando",
    checking: "Nombre en revisión",
    approved: "Nombre aprobado",
    defData: "Usar nombre provisional de la compañia"
  },
  dashboard: {
    completed: "Completado",
    processing: "Procesando",
    additionalServices: "Servicios Adicionales",
    greeting: "Buenas tardes, ",
    instruction: "Puedes revisar el estatus de tu proceso en la parte inferior",
    approved: "Aprobado",
    error: "Necesita revisión",
    incompleted: "Necesitas completar",
    time: "Hace 1 día",
    completedInfo: " Completar Información",
    unableDescription:
      "Necesitas completar los pasos anteriores para habilitar este paso",
    sendDocuments: "Enviar Documentos",
    sendDocumentsDescription:
      "Al enviar todos sus documentos se guardarán y no podrán editarse.",
    termsModal: {
      title: "Te invitamos a revisar las nuevas políticas y aceptarlas.",
      firstParagraph:
        "Manejamos la información que nos brindas para gestionar nuestras transacciones comerciales y mantener una relación cercana con nuestros clientes.",
      secondParagraph:
        "Queremos que sepas que tienes el derecho de acceder, actualizar, revocar, eliminar y oponerte al tratamiento de tus datos. Si deseas ejercer alguno de estos derechos, no dudes en enviarnos un correo electrónico a: soporte.arrancatunegocio@gmail.com. Estaremos encantados de ayudarte en lo que necesites.",
      imAccepted: "Acepto los ",
      termsAndConditions: "Términos y condiciones",
      and: "&",
      policies: "Políticas de Privacidad",
      legality: "Declaración de Legalidad y Licitud",
      shouldAccept: "Debes aceptar los términos",
      accept: "Aceptar"
    }
  },
  TermsAndConditions: {
    title: "TÉRMINOS Y CONDICIONES",
    firstTerm: "Objeto y alcance de estos términos",
    firstParagraph:
      "Estos Términos y Condiciones rigen los derechos y obligaciones en relación con el uso de los servicios del proveedor ARRANCA TU NEGOCIO S.A.S., con RUC: 1793193524001, en adelante, ”Arranca tu Negocio”, “ATN” o el “proveedor”, con domicilio en Quito D.M., Av. República del Salvador N 1082 y Naciones Unidas.",
    firstParagraphDotTwo:
      "La provisión del servicio consiste en la concesión de derechos de acceso y uso a la plataforma informática propiedad del proveedor a través de internet, en servidores que se encuentran dentro del ámbito de influencia del proveedor del servicio. Al utilizar ATN el usuario podrá introducir datos y recibir asesoría.",
    firstParagraphDotThree:
      "Una condición para el uso sin problemas del servicio es una conexión de internet continua y fiable hasta los servidores del proveedor del servicio. Depende del cliente establecer esta conexión con la ayuda de su dispositivo.",
    firstParagraphDotFour:
      "Arranca tu Negocio no será responsable bajo ninguna circunstancia de la veracidad de la información proporcionada por el usuario, quien se compromete a ingresar en la plataforma únicamente datos fidedignos. Así también Arranca tu Negocio no será responsable del uso y destino de la persona jurídica que a través de su plataforma se cree",
    secondTerm: "Formalización del contrato",
    secondParagraph:
      "Un contrato solo se formaliza al completar correctamente el proceso de registro mediante una confirmación del proveedor del servicio al usuario, en forma escrita por correo electrónico o mediante la prestación del servicio.",
    secondParagraphDotTwo:
      "Para utilizar nuestros servicios, el usuario debe aceptar completamente la Política de Privacidad, la Declaración de Licitud y Legalidad y estos Términos y Condiciones. Al aceptar, acepta que ha leído activamente y comprendido los documentos mencionados.",
    secondParagraphDotThree:
      "El requisito previo para el registro es que el usuario es legalmente competente y tiene la edad mínima de 18 años, es emprendedor o profesional autónomo o propietario de un negocio y utiliza los servicios exclusivamente para uso comercial de fines lícitos y legales.",
    thirthTerm: "Los servicios del proveedor de servicios",
    thirthParagraph:
      "El contenido y el alcance de los servicios se rigen por los acuerdos contractuales respectivos, además, exclusivamente de acuerdo con las funcionalidades del servicio descrito en la celebración del contrato en el sitio web del proveedor del servicio.",
    thirthParagraphDotTwo:
      "El proveedor de servicios proporciona a los usuarios diversos servicios de asesoría legal",
    thirthParagraphDotThree:
      "Los servicios prestados por el proveedor del servicio entre otros, incluyen, en particular, en el área tecnológica:",
    thirthParagraphActivities:
      "Actividades de diseño de la estructura y el contenido de los elementos siguientes (y/o escritura del código informático necesario para su creación y aplicación): programas de sistemas operativos (incluidas actualizaciones y parches de corrección), aplicaciones informáticas (incluidas actualizaciones y parches de corrección), bases de datos y páginas web.",
    thirthParagraphExtraActivities:
      "Actividades de planificación y diseño de sistemas informáticos que integran equipo y programas informáticos y tecnología de las comunicaciones.",
    thirthParagraphAdaptation:
      "Adaptación de programas informáticos a las necesidades de los clientes, es decir, modificación y configuración de una aplicación existente para que pueda funcionar adecuadamente con los sistemas de información de que dispone el cliente.",
    thirthParagraphSuministration:
      "Suministro de infraestructura para servicios de hospedaje, servicios de procesamiento de datos y actividades conexas. Incluye actividades especializadas de hospedaje, como: hospedaje de sitios web, aplicaciones, servicios de transmisión de secuencias de vídeo por internet.",
    thirthParagraphSecondActivity:
      "Actividades de procesamiento y suministro de servicio de registro de datos: elaboración completa de datos facilitados por los clientes, generación de informes especializados a partir de datos facilitados por los clientes.",
    applicationServices: "Servicios de aplicaciones.",
    thirthParagraphDotFour:
      "Solo el usuario respectivo tiene derecho a usar el servicio. La transferencia de la cuenta de usuario a terceros o cualquier otra opción de uso ofrecida por el usuario a terceros quedan expresamente prohibidos.",
    fourthTerm: "Obligaciones de los usuarios",
    fourthParagraph:
      "El usuario está obligado a proporcionar información veraz sobre sí mismo o su empresa, en relación con el uso del servicio.",
    fourthParagraphDotTwo:
      "Al utilizar el servicio, el usuario está obligado a cumplir con las leyes aplicables y a abstenerse de cualquier actividad que afecte o presione excesivamente la operación del servicio o la infraestructura técnica subyacente.",
    fourthParagraphDotThree:
      "El usuario no está autorizado a transmitir sus datos de inicio de sesión a terceros. El usuario está obligado a tratar sus datos de inicio de sesión cuidadosamente y evitar el uso indebido de los datos de inicio de sesión por parte de terceros.",
    fourthParagraphDotFour:
      "El usuario es el único responsable de cumplir con sus obligaciones de conservación. Se asegurará de que sus documentos y datos se mantengan legales, cuando sea necesario, y que las autoridades fiscales tengan el acceso necesario a ellos.",
    fifthTerm: "Aviso sobre el derecho de cancelación",
    fifthParagraph:
      "El proveedor de servicios ofrece sus servicios exclusivamente a personas naturales y empresas.",
    fifthParagraphDotTwo: "No existe el derecho de cancelación.",
    sixthTerm:
      "Precios y condiciones de pago, bloqueo de la cuenta, eliminación de cuenta, ajustes de precios",
    sixthParagraph:
      "El proveedor de servicios ofrece sus servicios en diferentes variantes de pago. Los precios acordados se pueden encontrar en la información de precios y pago vigentes.",
    sixthParagraphDotTwo:
      "El pago de los servicios contratados por el cliente a ATN será pagado mediante tarjeta de crédito.",
    sixthParagraphDotThree: "No se realizará un reembolso.",
    sixthParagraphDotFour:
      "Si los pagos del servicio no se pueden cobrar a tiempo desde la tarjeta de crédito el servicio no será proporcionado, siendo responsabilidad del usuario hacer el pago correspondiente para continuar con el proceso.",
    sixthParagraphDotFive:
      "El usuario acepta que el correo electrónico (proporcionada por el usuario) se podrá utilizará como medio para restablecer su contraseña, enviar comunicaciones, remitir documentos importantes y propios al proceso de constitución de su compañía.",
    seventhTerm: "Garantía y disponibilidad de servicios",
    seventhParagraph:
      "ATN tiene derecho a realizar cambios operativos en el sistema para mejoras sin notificarlo previamente al cliente.",
    eighthTerm: "Derechos de uso",
    eighthParagraph:
      "El usuario tiene derecho a acceder al sitio web para procesar sus datos.",
    eighthParagraphDotTwo:
      "El usuario puede usar Arranca tu Negocio solo para fines comerciales propios y solo para su propio personal.",
    eighthParagraphDotThree:
      "El cliente declara y garantiza que la información del cliente no infringirá los derechos de terceros ni los derechos de propiedad intelectual y no contendrá ningún material que sea obsceno, ofensivo, inapropiado o que infrinja cualquier ley aplicable.",
    ninethTerm: "Privacidad y datos del cliente",
    ninethParagraph:
      "El proveedor del servicio se asegurará de que los datos personales sean recopilados, almacenados y procesados por los usuarios solo en la medida en que sea necesario para la ejecución del contrato y esté permitido por la ley o lo que ordene el legislador. El proveedor del servicio tratará los datos personales de forma confidencial y según las disposiciones de la ley de protección de datos aplicable y no los divulgará a terceros, a menos que esto sea necesario para el cumplimiento de las obligaciones contractuales y/o exista una obligación legal de transmitirlo a terceros.",
    ninethParagraphDotTwo:
      "Con el fin de garantizar el procesamiento auditable de datos, se registra la creación, modificación y eliminación de datos con información del nombre del usuario y la fecha de procesamiento.",
    ninethParagraphDotThree:
      "El uso del servicio puede requerir que el proveedor del servicio procese datos personales en nombre del usuario. Para esto, se requiere la formalización de un acuerdo por separado para el procesamiento de datos personales. Las partes acuerdan que el cliente es el controlador de datos para cualquier información cargada a la aplicación de Arranca tu Negocio y que puede modificar o borrar esta información según sea necesario. Arranca tu Negocio es en todo momento el procesador de datos y procesa los datos en nombre del cliente.",
    ninethParagraphDotFour:
      "Arranca tu Negocio solo procesará los datos del cliente de acuerdo con las instrucciones del cliente y no para un uso propio no autorizado.",
    ninethParagraphDotFive:
      "Arranca tu Negocio mantendrá la confidencialidad de toda la información confidencial del cliente que éste proporcione a Arranca tu Negocio.",
    tenthTerm: "Cambios en los servicios",
    tenthParagraph:
      "El proveedor del servicio ajusta periódicamente sus servicios prestados en internet, a su propia discreción, al desarrollo tecnológico y las necesidades del mercado para cumplir con el uso previsto de acuerdo con la descripción del producto. Esto puede cambiar el contenido del servicio, como una funcionalidad nueva o modificada y adaptaciones a nuevas tecnologías. Dado que estos cambios forman parte de la naturaleza del software, el usuario no puede derivar ningún derecho o reclamo de esto.",
    eleventhTerm: "Límite de responsabilidad",
    eleventhParagraph:
      "Arranca tu Negocio no es responsable de las interrupciones del servicio debido a fuerza mayor, en particular durante una caída o sobrecarga de las redes de comunicaciones mundiales. Por esta razón, el cliente no puede reclamar una reducción de su obligación de servicio.",
    eleventhParagraphDotTwo:
      "Arranca tu Negocio no es responsable de la información publicada sobre sus servicios. El emisor es responsable de su precisión, integridad e intemporalidad.",
    twelfthTerm: "Cambios de los Términos y Condiciones",
    twelfthParagraph:
      "El proveedor del servicio se reserva el derecho de cambiar estos términos y condiciones en cualquier momento con efectividad incluso dentro de las relaciones contractuales existentes, siempre que este cambio, cumpla la normativa."
  },
  privacyAndDataUsagePolicy: {
    title: "POLÍTICA DE PRIVACIDAD Y USO DE DATOS.",
    firstParagraph:
      "ARRANCA TU NEGOCIO S.A.S. (en adelante, también “ATN”), es respetuosa de los datos personales e información que le suministran sus clientes actuales, pasados y potenciales, sus aliados comerciales, sus proveedores, sus visitantes, sus colaboradores, sus transportadores y cualquier otro tercero (en adelante, los Titulares). De acuerdo con lo anterior, en la presente Política de Privacidad (en adelante, la Política), se establecen las finalidades, medidas y procedimientos de nuestras bases de datos, así como los mecanismos con que los Titulares cuentan, para conocer, acceder, rectificar y actualizar, rectificar y actualizar, eliminar, oponerse, portar, suprimir y a no ser objeto de una decisión basada única o parcialmente, en valoraciones automatizadas; los datos suministrados o revocar la autorización que se otorga, con la aceptación de la presente Política.",
    secondParagraph:
      "A los interesados les informamos, que al suministrar sus datos personales con la adquisición de los servicios ofrecidos, la presentación de quejas o reclamos, la celebración de contratos con ARRANCA TU NEGOCIO S.A.S. el ingreso a las instalaciones de ARRANCA TU NEGOCIO S.A.S. y/o la aceptación expresa o inequívoca de acuerdos de confidencialidad y formatos de vinculación; implica la aceptación de los Titulares, de la presente Política y su autorización para los usos y tratamientos de datos que aquí se describen.",
    point1Title: "RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE DATOS.",
    point1Paragraph:
      "El responsable del tratamiento de datos personales y otra información de los Titulares, es la sociedad ARRANCA TU NEGOCIO S.A.S.con domicilio y dirección, Quito D.M., Av. República del Salvador N 1082 y Naciones Unidas. En caso de reclamos, consultas o peticiones sobre datos personales; ARRANCA TU NEGOCIO S.A.S. podrá ser contactado en la dirección indicada o por medio del correo electrónico: soporte.arrancatunegocio@gmail.com o al teléfono: 0983361230.",
    point2Title: "DEFINICIONES",
    point2FirstParagraph:
      "Base de Datos: Conjunto estructurado de datos cualquiera que fuera la forma, modalidad de creación, almacenamiento, organización, tipo de soporte, tratamiento, procesamiento, localización o acceso, centralizado, descentralizado o repartido de forma funcional o geográfica.",
    point2SecondParagraph:
      "Consentimiento: Manifestación de la voluntad libre, específica, informada e inequívoca, por el que los Titulares de los datos personales autorizan a ARRANCA TU NEGOCIO S.A.S. a tratar los mismos, bajo las directrices de la presente Política.",
    point2ThirdParagraph:
      "Dato Personal: Dato que identifica o hace identificable a una persona natural, directa o indirectamente.",
    point2FourthParagraph:
      "Datos Sensibles: Datos relativos a: etnia, identidad de género, identidad cultural, religión, ideología, filiación política, pasado judicial, condición migratoria, orientación sexual, salud, datos biométricos, datos genéticos y aquellos cuyo tratamiento indebido pueda dar origen a discriminación, atenten o puedan atentar contra los derechos y libertades fundamentales.",
    point2FifthParagraph:
      "Datos crediticios: Datos que integran el comportamiento económico de personas naturales, para analizar su capacidad financiera.",
    point2SixthParagraph:
      "Responsable del Tratamiento: persona natural o jurídica, pública o privada, autoridad pública, u otro organismo, que solo o conjuntamente con otros decide sobre la finalidad y el tratamiento de datos personales.",
    point2SeventhParagraph:
      "Titular: Persona natural cuyos datos personales son objeto de tratamiento",
    point2EighthParagraph:
      "Transferencia o comunicación: Manifestación, declaración, entrega, consulta, interconexión, cesión, transmisión, difusión, divulgación o cualquier forma de revelación de datos personales realizada a una persona distinta de los Titulares, responsable o encargado del tratamiento de datos personales.",
    point2NinthParagraph:
      "Tratamiento: Cualquier operación o conjunto de operaciones realizadas sobre datos personales, ya sea por procedimientos técnicos de carácter automatizado, parcialmente automatizado o no automatizado, tales como: la recogida, recopilación, obtención, registro, organización, estructuración, conservación, custodia, adaptación, modificación, eliminación, indexación, extracción, consulta, elaboración, utilización, posesión, aprovechamiento, distribución, cesión, comunicación o transferencia, o cualquier otra forma de habilitación de acceso, cotejo, interconexión, limitación, supresión, destrucción y, en general, cualquier uso de datos personales.",
    point3Title: "FINALIDAD DE LAS BASES DE DATOS",
    point3Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. cuenta con una base de datos, en la se encuentran los datos personales de los Titulares. Las bases de datos de ATN tienen como finalidades principales, las siguientes:",
    point3LetterA:
      "Crear a partir de tales datos una (o más) Sociedad de Acciones Simplificadas.",
    point3LetterB:
      "Crear los formularios necesarios para obtener una cuenta bancaria y/o tarjeta de crédito",
    point3LetterC: "Proveer servicios de contabilidad.",
    point3LetterD: "Ofrecer servicios jurídicos.",
    point3LetterE: "Proveer servicios de marketing digital.",
    point3LetterF:
      "La comunicación con los Titulares para efectos comerciales o de mercadeo.    ",
    point3LetterG: "Responder inquietudes y solventar reclamos.",
    point3LetterH: "El desarrollo de proyectos, productos y concursos",
    point3LetterI:
      "La socialización de políticas, proyectos, programas y cambios organizacionales.",
    point3LetterJ:
      "La realización de análisis estadísticos, comerciales, estratégicos, financieros, sociales, técnicos y calificación de riesgo.",
    point3LetterK:
      "Consultar, reportar y actualizar información comercial, patrimonial o crediticia y financiera en burós de crédito y/o bases de datos con el mismo fin. El tratamiento de estos datos, únicamente será utilizado por ARRANCA TU NEGOCIO S.A.S. para la finalidad de análisis; por lo que, no serán comunicados o difundidos, ni tampoco tendrán una finalidad secundaria.",
    point3LetterL:
      "La implementación de estrategias comerciales, laborales, organizacionales y de mercadeo",
    point3LetterM: "El control y la preservación de la seguridad en ATN",
    point3LetterN:
      "Dar cumplimiento a las obligaciones impuestas por leyes y normas internas de ATN",
    point3LetterO:
      "Cualquier otro requerimiento, generado por la relación comercial y social entre ARRANCA TU NEGOCIO S.A.S. y los Interesados",
    point3SecondParagraph:
      "Este tratamiento está legitimado por el artículo 7, numerales 1, 2, 5 y 8 de la Ley Orgánica de Protección de Datos Personales (en adelante, la LOPDP).",
    point3ThirdParagraph:
      "En el caso de que los Titulares no entreguen sus datos o estos sean erróneos o inexactos, es posible que las finalidades aquí descritas, no puedan ser tratadas por ARRANCA TU NEGOCIO S.A.S. impidiendo el cabal cumplimiento de las mismas o imposibilitando el desarrollo de las actividades descritas en la presente cláusula.",
    point4Title: "FORMA DE OBTENCIÓN Y DATOS SUMINISTRADOS",
    point4Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. podrá pedir expresamente a los Titulares o recolectar de su comportamiento, los datos que sean necesarios para cumplir la finalidad de las Bases de Datos; los cuales podrán ser -entre otros- sus nombres y apellidos, número de identificación (cédula de ciudadanía o de identidad o pasaporte o visas), fecha de nacimiento, dirección de correspondencia, teléfono de contacto, correo electrónico, nombre de su compañía, capital a invertir, capital social, dirección de la compañía, dirección personal, necesidades e intereses, certificado de registro único de contribuyentes, firma electrónica e imágenes en cámaras de vigilancia u otro tipo de formatos. Los datos podrán ser suministrados explícitamente a ARRANCA TU NEGOCIO S.A.S. mediante su plataforma https://arrancatunegocio.com/, mensajería instantánea, correos electrónicos, recolectados personalmente a través de sus empleados, prestadores de servicios, obtenidos mediante consulta a terceros, que administren bases de datos o recolectados implícitamente de las operaciones de análisis de mercado, de grupos objetivo, adquisición de los servicios que son ofrecidos por ARRANCA TU NEGOCIO S.A.S. o de los comportamientos de los Titulares como reclamaciones, solicitudes, encuestas, propuestas, ofertas, visita de las instalaciones, de participación en proyectos, programas y eventos, entre otros.",
    point5Title:
      "TRATAMIENTO DE LOS DATOS PERSONALES ALMACENADOS EN LAS BASES DE DATOS DE ATN",
    point5Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. solo almacenará, usará, procesará y comunicará los datos personales y otra información de los Titulares, para las finalidades descritas y para los tratamientos autorizados por el Titular, en el consentimiento informado, en esta Política o en las leyes vigentes. En adición a lo mencionado en otras cláusulas, los Titulares expresamente, autorizan a ATN para la recolección, uso y circulación de sus datos personales y otra información, para los siguientes propósitos y en las siguientes circunstancias:",
    point5LetterA:
      "Establecer comunicación entre ARRANCA TU NEGOCIO S.A.S. y los Titulares para cualquier propósito relacionado con las finalidades que se establecen en la presente Política, ya sea mediante llamadas, mensajes de texto, correos electrónicos y/o físicos.",
    point5LetterB:
      "Efectuar o implementar la adquisición u oferta de productos o servicios por parte de ATN",
    point5LetterC:
      "Auditar, estudiar y analizar la información de la Base de Datos para diseñar estrategias comerciales y aumentar y/o mejorar los Productos y Servicios que ofrece ATN.",
    point5LetterD:
      "Auditar, estudiar y analizar la información de la Base de Datos para diseñar estrategias de seguridad",
    point5LetterE:
      "Auditar, estudiar, analizar y utilizar la información de la Base de Datos para diseñar, implementar y desarrollar: programas, proyectos y eventos.",
    point5LetterF:
      "Auditar, estudiar, analizar y utilizar la información de la Base de Datos para la socialización de políticas, proyectos, programas, resultados y cambios organizacionales.",
    point5LetterG:
      "Suministrar la información y datos personales de los Titulares a aliados estratégicos para que estos contacten a los Titulares para ofrecerles bienes y servicios de su interés, recibir ofertas de los Titulares, invitar a la participación en programas, proyectos eventos, socializar políticas, proyectos, programas, resultados y cambios organizacionales.",
    point5LetterH:
      "La revelación, cuando la información deba ser entregada a un tercero para cumplir con leyes, regulaciones o procesos legales, para asegurar el cumplimiento de los términos y condiciones, para detener o prevenir fraudes, ataques a la seguridad de ARRANCA TU NEGOCIO S.A.S. o de otros, prevenir problemas técnicos o proteger los derechos de otros como lo requieran los términos y condiciones o la Ley",
    point5SecondParagraph:
      "Este tratamiento está legitimado por el artículo 7, numerales 1, 2, 5 y 8 de la LOPDP.",
    point5ThirdParagraph:
      "No existen otros tratamientos adicionales o ulteriores de los planteados en la presente Política.",
    point6Title: "AUTORIZACIÓN POR CONDUCTA INEQUÍVOCA DEL TITULAR",
    point6Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. podrá realizar el tratamiento de datos personales de Titulares que hayan otorgado su autorización a través de consentimientos informados que permitan concluir de forma razonable, que existe dicha autorización. En este sentido, ARRANCA TU NEGOCIO S.A.S. tratará los datos de los Titulares, en los casos en donde los Titulares contacten directamente a ARRANCA TU NEGOCIO S.A.S.y remitan sus datos personales, para los fines de iniciar una relación comercial, contractual o laboral o para la atención de consultas, reclamos o peticiones, entre otros.",
    point6SecondParagraph:
      "Los Titulares, podrán hacer efectivos sus derechos, en cualquier momento y de las maneras establecidas en esta Política.",
    point7Title: "CAMBIOS EN LA POLÍTICA DE PRIVACIDAD",
    point7Paragraph:
      "Cualquier cambio sustancial en las políticas de tratamiento, será comunicado oportunamente a los Titulares, mediante la publicación en nuestros portales web y/o mediante correo electrónico.",
    point8Title: "ALMACENAMIENTO DE DATOS PERSONALES",
    point8Paragraph:
      "El Titular autoriza expresamente a ARRANCA TU NEGOCIO S.A.S. para que éste, almacene sus datos de la forma que considere más oportuna, siempre que cuente con medidas de seguridad, que permitan la protección de los datos de los Titulares",
    point9Title:
      "MEDIDAS DE SEGURIDAD PARA LA PROTECCIÓN DE LOS DATOS PERSONALES Y OTRA INFORMACIÓN",
    point9Paragraph:
      "Las medidas de seguridad con las que cuenta ARRANCA TU NEGOCIO S.A.S. buscan proteger los datos de los Titulares, en aras de impedir su adulteración, pérdida, usos y accesos no autorizados. Para ello, ARRANCA TU NEGOCIO S.A.S. de forma diligente, implementa medidas de protección humanas, administrativas y técnicas, que razonablemente están a su alcance.",
    point9SecondParagraph:
      "El Titular acepta expresamente, esta forma de protección y declara que la considera conveniente y suficiente, para los propósitos de almacenar y utilizar sus datos personales.",
    point10Title: "DERECHOS DE LOS TITULARES",
    point10Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. informa a los Titulares que, conforme a la legislación vigente, estos tienen los siguientes derechos:",
    point10LetterA: "Derecho a la información",
    point10LetterB: "Derecho de acceso",
    point10LetterC: "Derecho de rectificación y actualización",
    point10LetterD: "Derechos de eliminación",
    point10LetterE: "Derecho de oposición",
    point10LetterF: "Derecho a la portabilidad",
    point10LetterG: "Derecho a la supresión del tratamiento",
    point10LetterH:
      "Derecho a no ser objeto de una decisión basada única o parcialmente en valoraciones automatizadas",
    point10SecondParagraph:
      "ARRANCA TU NEGOCIO S.A.S. se reserva el derecho de seguir tratando los datos de los Titulares, por lo que, no se podrá ejercer los derechos de rectificación, actualización, eliminación, oposición, anulación y portabilidad, en los siguientes casos:",
    point10I:
      "Si los Titulares no son titulares de los datos personales o su representante legal no se encuentre debidamente acreditado;",
    point10II:
      "Cuando los datos personales son necesarios para el cumplimiento de una obligación legal o contractual;",
    point10III:
      "Cuando los datos personales son necesarios para el cumplimiento de una orden judicial, resolución o mandato motivado de autoridad competente;",
    point10IV:
      "Cuando los datos personales son necesarios para la formulación, ejercicio o defensa de reclamos o recursos",
    point10V:
      "Cuando se pueda causar perjuicios a derechos o afectación a intereses legítimos de terceros y ello sea acreditado por ARRANCA TU NEGOCIO S.A.S. al momento de dar respuesta a alguna solicitud, en ejercicio del derecho respectivo por parte de los Titulares;",
    point10VI:
      "Cuando se pueda obstaculizar actuaciones judiciales o administrativas en curso, debidamente notificadas;",
    point10VII:
      "Cuando los datos personales, son necesarios para ejercer el derecho a la libertad de expresión y opinión; y",
    point10ThirdParagraph:
      "En los casos en los que medie el interés público, sujeto al cumplimiento de los estándares internacionales de derechos humanos aplicables a la materia, al cumplimiento de los principios de la LOPDP y a los criterios de legalidad, proporcionalidad y necesidad.",
    point11Title: "TRANSFERENCIAS O COMUNICACIONES DE DATOS",
    point11Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. podrá comunicar los datos personales de los Titulares, a países donde el grupo empresarial ARRANCA TU NEGOCIO S.A.S. tenga operaciones o a socios locales a fin del cumplimiento de la prestación de su servicio o producto.",
    point11SecondParagraph:
      "Posiblemente, algunos de los países a los que se comunicarán los datos personales, no cuenten con normas o estándares de Ecuador; sin embargo, ARRANCA TU NEGOCIO S.A.S. tomará todas las medidas necesarias, de conformidad con la LOPDP, para que los datos personales de los Titulares, estén resguardados.",
    point12Title: "PETICIONES, CONSULTAS, QUEJAS, RECLAMOS Y CONTACTO",
    point12Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. dispone de los medios y recursos para atender y gestionar las peticiones, consultas y reclamos de los Titulares o sus causahabientes para ejercer sus derechos. El medio de comunicación para este fin, es el correo electrónico: o a la dirección de ARRANCA TU NEGOCIO S.A.S. Av. República del Salvador N 1082 y Naciones Unidas, Quito, Ecuador.",
    point12SecondParagraph:
      "Cualquier duda o información adicional, será recibida y tramitada mediante su envío a la dirección física o electrónica de contacto establecidas.",
    point13Title: "PROCEDIMIENTO PARA EJERCER SUS DERECHOS",
    point13Paragraph:
      "En caso de que desee ejercer sus derechos, los Titulares deberán enviar un correo electrónico o físico a las direcciones de contacto establecidas en la presente Política.",
    point13SecondParagraph:
      "En caso de que la solicitud sea presentada por el causahabiente del titular, este deberá demostrar dicha condición, por documento que pruebe la calidad de sucesor de la persona fallecida.",
    point13ThirdParagraph:
      "En caso de que la solicitud sea presentada por el representante o apoderado del titular, este deberá acreditar dicha representación o apoderamiento, a través de documento idóneo y suficiente, como es el poder, la escritura de posesión efectiva o el certificado de nacimiento.",
    point13FourthParagraph:
      "El procedimiento que se seguirá para dichas comunicaciones, será el que se indica a continuación:",
    point13FifthParagraph:
      "Peticiones y Consultas sobre Datos Personales: Cuando el titular de los datos o sus causahabientes, representantes o apoderados deseen consultar la información que reposa en la base de datos, ARRANCA TU NEGOCIO S.A.S. responderá la solicitud, en plazo de máximo quince (15) días hábiles.",
    point13SixthParagraph:
      "Reclamos para la rectificación y actualización, eliminación y oposición de Datos Personales: Cuando el 5 titular de los datos o sus causahabientes, representantes o apoderados, consideren que la información contenida en las bases de datos, debe ser objeto de corrección, actualización o eliminación o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la LOPDP; podrán presentar un reclamo ante ARRANCA TU NEGOCIO S.A.S. el cual será tramitado bajo las siguientes reglas:",
    point13SeventhParagraph:
      "El reclamo se formulará mediante solicitud dirigida a ARRANCA TU NEGOCIO S.A.S. con la identificación de los Titulares, la descripción de los hechos que dan lugar al reclamo, la dirección, y se anexarán los documentos que se quieran hacer valer. ARRANCA TU NEGOCIO S.A.S. atenderá este requerimiento, en el plazo máximo de quince (15) días hábiles. Si el reclamo resulta incompleto, ARRANCA TU NEGOCIO S.A.S. podrá requerir al interesado, dentro de los cinco (5) días calendario, siguientes a la recepción del reclamo, para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo y deberá ingresar uno nuevo, para seguir con el proceso mencionado.",
    point13EighthParagraph:
      "El término máximo para atender el reclamo, será de quince (15) días hábiles, contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al solicitante, los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. La rectificación, actualización, eliminación, oposición, anulación y portabilidad, no procederán cuando se cumpla con alguna de las excepciones descritas en el punto 11 de esta Política.",
    point13NinthParagraph:
      "En caso de que ARRANCA TU NEGOCIO S.A.S.no cumpla con los requerimientos realizados por los Titulares o existiere demoras no justificadas, los Titulares podrán realizar sus reclamos directamente con la Autoridad de Protección de Datos ecuatoriana.",
    point14Title: "PERÍODO DE VIGENCIA DE LA BASE DE DATOS",
    point14Paragraph:
      "Los datos personales incorporados en la Base de Datos, estarán vigentes durante el plazo necesario para cumplir las finalidades para las cuales fueron recolectados; a menos que, el titular contacte a ARRANCA TU NEGOCIO S.A.S. por los medios definidos en el numeral 12 de esta política y ejerza su derecho a la supresión o eliminación de sus datos personales.",
    point14SecondParagraph:
      "En el supuesto de que alguna normativa referente a este punto, establezca plazos determinados, ARRANCA TU NEGOCIO S.A.S. se compromete a tratar los datos personales de los Titulares, hasta el plazo máximo establecido en dicha normativa.",
    point15Title: "LEGISLACIÓN VIGENTE",
    point15Paragraph:
      "La norma que regula la protección de los datos personales en el Ecuador, es la Ley Orgánica de Protección de Datos Personales, su reglamento y las directrices emitidas por la autoridad competente."
  },
  legalityAndLegitimacyStatement: {
    title: "DECLARACIÓN DE LEGALIDAD Y LICITUD",
    firstParagraph:
      "El usuario de Arranca tu Negocio S.A.S. (“ATN” o “Arranca tu Negocio”) acepta el cuestionario que a continuación se coloca, tal y como sigue:",
    firstLine:
      "¿Los accionistas fundadores de esta Sociedad por Acciones Simplificada son personas naturales o jurídicas, hábiles y con capacidad para contratar?",
    secondLine:
      "¿La Sociedad por acciones simplificadas se dedicará a actividades relacionadas con operaciones financieras, de mercado de valores, seguros y otras que tengan un tratamiento especial, de acuerdo con la Ley?",
    thirdLine:
      "¿La información proporcionada durante este proceso de constitución es veraz y auténtica?",
    fourthLine:
      "¿La documentación de soporte presentada durante este proceso de constitución es veraz y auténtica?",
    fifthLine:
      "¿La constitución de esta Sociedad por Acciones Simplificada cumple con todos los requisitos establecidos por la Ley de Compañías?",
    sixthLine:
      "¿Los fondos utilizados en la integración del capital social de esta Sociedad por Acciones Simplificada provienen de actividades ilícitas?",
    seventhLine:
      "¿La Sociedad por Acciones Simplificada tendrá transacciones financieras o negocios comerciales vinculados a actividades ilícitas, tales como, narcotráfico, lavado de dinero o terrorismo?",
    yes: "SI",
    no: "NO"
  },
  PageHelp: {
    title: "Ayuda",
    subtitle:
      "Encuentra una respuesta rápida a tus inquietudes; sino fuese suficiente, puedes contactar con soporte para una guía personalizada.",
    faq: "Preguntas Frecuentes",
    moreHelp: "¿Necesitas ayuda con algo más?",
    shareHolder: "Accionistas  no actualizada",
    generalInfo: "Información general incorrecta",
    capitalAsigned: "Capital asignado incorrecto",
    supportContact: "Contactar con soporte"
  },
  SignUpUserForm: {
    title: "Solo un paso mas",
    confirm: "Confirmar",
    acceptError: "Debes aceptar los términos para continuar",
    terms1: "Acepto los ",
    terms2: "Términos y condiciones",
    and: "&",
    policies: "Políticas de Privacidad",
    legality: "Declaración de Legalidad y Licitud"
  },
  Formation: {
    title: "Formación",
    subtitle:
      "Aquí puedes revisar el contrato de tu empresa y los nombramientos de los representantes legales.",
    complete: "Completar información",
    fill: "Llenar formulario"
  },
  Shareholders: {
    title: "Accionistas",
    subtitle:
      "Recuerda que ser accionista de una empresa, equivale a ser su dueño.",
    titleCollapse: "Accionista",
    type: "Tipo de accionista",
    identification: "Identificación",
    number: "Nro de identificación",
    name: "Nombres / Razón social",
    phone: "Teléfono Convencional",
    mobile: "Teléfono Celular",
    email: "Correo",
    country: "País",
    city: "Ciudad",
    main: "Calle principal",
    numberBusiness: "Nro del domicilio",
    intersection: "Intersección",
    reference: "Referencia",
    add: "Añadir nuevo accionista",
    passport: "PASAPORTE",
    option_one: "Persona Natural Ecuatoriana",
    option_two: "Empresa ecuatoriana",
    option_three: "Accionista extranjero",
    representative: "Representante legal",
    delete: "Eliminar",
    unique: "Soy el único accionista de la empresa",
    useLoginDataSwitch: "Usar datos de usuario logueado",
    uniqueRuleText: "El número de identificación debe ser único."
  },
  Representatives: {
    title: "Representantes legales",
    subtitle:
      "Ingresa los datos completos de las personas que van a representar y administrar a tu compañía.",
    titleCollapse: "Representante legal",
    directionDetail: "Detalles de dirección",
    add: "Añadir nuevo integrante",
    assign: "Asignar",
    input_one: "Cargo",
    input_two: "Tipo de representación",
    input_three: "Persona asignada",
    modal_one: {
      title: "Asigna los cargos",
      subtitle:
        "Los cargos, son  la denominación con que se conocerá al administrador o representante legal de tu compañía. El tipo de representación legal que  decidas darle definirá la importancia del cargo.",
      option_one: "Presidente",
      option_two: "Gerente"
    },
    modal_two: {
      title: "Asigna los tipos de representación",
      subtitle:
        "La representación legal es la capacidad de representar a la empresa en obligaciones y derechos.",
      option_one: "Titular y subrogante",
      option_two: "Individual",
      option_three: "Conjunta"
    },
    modal_three: {
      title: "Asigna los representantes legales",
      subtitle:
        "Los cargos, son  la denominación con que se conocerá al administrador o representante legal de tu compañía. El tipo de representación legal que  decidas darle definirá la importancia del cargo."
    }
  },
  HelpForm: {
    title: "¿Aún necesitas ayuda?",
    additionalComments: "Comentarios Adicionales",
    addComments: "Agrega comentarios adicionales",
    sendComments: "Enviar Comentarios",
    problemNotFixed: "Mi problema no se ha solucinado",
    dontUnderstand: "No entiendo los pasos a seguir"
  },
  TipModal: {
    title: "¡Tip!",
    BusinessNamePage: {
      subtitle: " Elige el mejor nombre para tu empresa",
      tip1: "Nombres relacionados con tu actividad",
      tip1Subtitle:
        "“Comercializadora”, “Importadora”, “Florícola”, “Asesoría”, “Servicios de...”,”Restaurante”, “Estética”",
      tip2: "Utiliza tus nombres y apellidos o los de tus socios",
      tip2Subtitle:
        "“Pérez y Salazar”, “Terán”, “Ontaneda”  “Sol Freile Andrade”",
      tip3: "No utilizar caracteres especiales",
      tip3Subtitle:
        "Ampersand (&), Comillas (“”), Paréntesis (()), Guión (-), Barra diagonal (/), tilde e inlcusive la letra “ñ”."
    },
    CapitalForm: {
      subtitle:
        "Toma en cuenta los siguientes consejos al momento de definir los accionistas de tu empresa. Recuerda que ser accionista de una empresa, equivale a ser su dueño.",
      tip1: "Tu cónyuge no puede ser accionista",
      tip1Subtitle:
        "Tú y tu cónyuge (espos@) no pueden ser accionistas de una misma empresa. Puedes ser accionista tú o tu espos@, nunca ambos.",
      tip2: "¡Tú puedes ser el único dueño!",
      tip2Subtitle:
        "¿No cuentas con más accionistas? ¡No te preocupes! Tú puedes ser el único accionista (dueño) de tu empresa.",
      tip3: "No hay un límite de accionistas",
      tip3Subtitle:
        "¿Cuentas con varias peronas que serán accionistas? Agrega las que necesitas.",
      titleModal2: "Error",
      tipTitleModal2: "No se pueden guardar los accionistas",
      tipSubTitleModal2: "Debe guardar al menos 1 accionista para su empresa."
    },
    button: "Entendido"
  },
  Terminology: {
    title: "Terminologia"
  },
  Documents: {
    title: "Documentos",
    subtitle:
      "A continuación encontrarás los documentos que se han generado  de tu emrpesa.",
    formation: "Formación",
    taxes: "Impuestos",
    download: "Descargar",
    creationDate: "Fecha de creación"
  },
  SaveDiscardButton: {
    save: "Guardar",
    discard: "Descartar",
    continue: "Guardar y continuar luego",
    send: "Guardar y enviar",
    toolTipNext: "Siguiente Paso",
    toolTipBack: "Paso Anterior"
  },
  BusinessPageHeader: {
    editProfile: "Editar perfil",
    logout: "Cerrar sesión"
  },
  DiscardModal: {
    title: "Si descartás perderas la informacion",
    subtitle: "Si descartas no podrás recuperar la información.",
    discard: "Descartar",
    cancel: "Cancelar"
  },
  StepWarningModal: {
    title: "¿Deseas guardar los cambios antes de continuar?",
    subtitle:
      "Guarda los cambios antes de continuar para no perder ninguna información",
    discard: "Guardar y continuar",
    cancel: "Continuar sin guardar"
  },
  ChangeMail: {
    changeMail: "A continuación podrás cambiar tu correo electrónico",
    newMail: "Nuevo correo electrónico",
    confirmMail: "Confirmar correo electrónico",
    change: "Cambiar correo ",
    cancel: "Cancelar"
  },
  AdminBusinessHeder: {
    downloadPdf: "Descargar PDF",
    dowloadExcel: "Descargar Excel"
  },
  BusinessList: {
    business: "Empresas",
    sidebar: {
      menu: {
        business: "Empresas",
        formation: "Formularios"
      },
      items: {
        all: "Todas",
        done: "Completas",
        review: "Necesitan revision"
      }
    }
  },
  BusinessInfoSteps: {
    generalInfo: "Información General",
    shareholders: "Accionistas",
    legalRepresentative: "Representantes Legales",
    nameBusiness: "Reserva de nombre (razón social)",
    capital: "Capital"
  },
  AdminFormHeader: {
    state: "Estado",
    comment: "Comentario",
    update: "Actualizar",
    aproved: "Completo",
    rejected: "Necesita revisión",
    addComment: "Agregar comentario",
    commentTitle: "Titluo del Comentario"
  },
  landingAlliance: {
    AllianceSection: {
      title: "Allianza",
      description:
        "La creación de tu empresa cuenta con el respaldo de importantes empresas. "
    },
    AllianceList: {
      Trade: {
        title: "Trade",
        subtitle: "Capacidad Tecnológica",
        description:
          "Somos una agencia de tecnología que utiliza el análisis de data y diseño de experiencia para crear soluciones con alta capacidad transaccional."
      },
      Ponce: {
        title: "Ponce Estudio Jurídico",
        subtitle: "Seguridad Legal",
        description:
          "Prestamos servicios jurídicos especializados en las áreas fundamentales del derecho. La tradición y la innovación se fusionan en Ponce Estudio Jurídico."
      }
    }
  },
  Country: {
    slogan: "Nos expandimos constantemente",
    title: "Nuestra meta es alcanzar a toda latinoamerica",
    subtitle:
      "Buscamos seguir creciendo en el mercado latinoamercano y ayudar cada vez a más usuarios a crear su empresa totalmente en línea."
  },
  BusinessSelect: {
    title: "Selecciona una empresa para ingresar",
    subtitle: "Selecciona tu empresa",
    description: "Selecciona la empresa con la que quieres ingresar.",
    logOutQuestion: "¿Quieres inciar con otra cuenta?",
    logOut: "Cerrar sesión"
  },
  AnonymousEmail: {
    email: "Correo electrónico",
    emailDescription:
      "El correo que utilices recibirá toda la información de tu empresa"
  },
  CardHelp: {
    title: "¿No tienes un nombre definido?",
    subtitle:
      " No importa si no tienes un nombre definido, luego podrás modificarlo.",
    button: "Entendido"
  },
  nameChecker: {
    probabilities: ["...", "Baja", "Media", "Mayor al promedio", "Alta"],
    title: "La probabilidad de que este nombre pase es",
    suggestion1: "Más de 4 caracteres",
    suggestion2: "Al menos 3 palabras",
    suggestion3: "Al menos una palabra única",
    suggestion4: " No utilizar caracteres especiales "
  },
  Checkout: {
    title: "Estás a un paso de crear tu empresa",
    subtitle: "Completa tus datos personales",
    billingTitle: "Datos de facturación",
    myDataCheckout: "Mis datos de facturación",
    billingAction: "Cambiar",
    billingActionCompaniesNull: "Agregar",
    methodToPay: "Métodos de pago",
    emailAccount: "Correo de la cuenta",
    dataAccount: "Datos de la cuenta",
    isCash: "Link de pagos",
    isCard: "Tarjeta de Crédito",
    errorMessageSave: "Ocurrió un problema al guardar el dato de facturación",
    errorMessageUpdate:
      "Ocurrió un problema al actualizar el dato de facturación",
    errorMessagePhone: "El teléfono ingresado no es válido",
    address: "Dirección",
    addAddress: "Agregar nueva dirección",
    selectAddress: "Selecciona una dirección",
    businessName: "Razón social",
    name: "Nombre",
    lastName: "Apellido",
    documentType: "Tipo de identificación",
    documentNumber: "Número de documento",
    email: "Correo electrónico",
    phone: "Teléfono",
    saveAddress: "¿Utilizar una dirección guardada?",
    default: "Usar como predeterminada",
    saveBilling: "Guardar datos de facturación",
    terms1: "Acepto los ",
    terms2: "términos y condiciones",
    policies: "Políticas de Privacidad",
    and: "&",
    legality: "Declaración de Legalidad y Licitud",
    business1: "Acepto que mi empresa no se dedica a actividades como",
    business2: "transporte, seguridad, capitales, bancos, seguros.",
    business3:
      "Para crear este tipo de empresa nuestro personal se contactará contigo.",
    company1:
      "Acepto y declaro que mi empresa no será un holding o tenedora de acciones, ni se vinculará a través de su nombre a un Holding.",
    errorCheck: "Debes aceptar los campos",
    pay: "Pagar ahora",
    securePay: "Compra segura",
    textFooterCard:
      "Realiza tu compra segura, cualquier inquietud te ofrecemos el soporte que necesites."
  },
  DetailDocuments: {
    title: "Detalle de datos ingresados",
    subtitle:
      "Revisa la información ingresada. Recuerda que una que vez que la información sea enviada no podrá ser editada.",
    personalInformation: "Información personal",
    nameReservation: "Reserva de nombre",
    shareholders: "Accionistas",
    legalRepresentatives: "Representantes legales",
    percentageOfShares: "Porcentaje de acciones",
    edit: "Editar",
    back: "Volver atrás",
    sendDocuments: "Enviar Documentos",
    titleModal: "¿Seguro deseas enviar tus documentos?",
    subtitleModal: "Una vez que envíes tus documentos no podrás editarlos.",
    confirmText: "Sí, enviar documentos",
    cancelText: "Cancelar",
    confirmSend: "¡Tus datos han sido enviados!",
    confirmSubtitle:
      "Nos contactaremos contigo para mantenerte al tanto sobre tu nueva empresa."
  },
  menuMobile: {
    start: "Inicio",
    documents: "Documentos",
    partners: "Socios",
    profile: "Perfil"
  },
  dashboardHide: {
    title: "Nos encontramos trabajando en la versión móvil de tu dashboard",
    subtitle:
      "Ingresa desde una computadora de escritorio para revisar tu información.",
    button: "Ir al inicio"
  },
  termsLanding: {
    title: "Términos legales",
    subtitle: "Propuesta y validación legal"
  },
  CongratsModal: {
    congrats: "¡Felicidades!",
    check:
      "Revisa y llena todos los campos que están pendientes en el dashboard.",
    button: "Comenzar",
    review:
      "Ya puedes continuar con el proceso de creación de tu empresa, mientras verificamos todos tus datos"
  },
  Coupon: {
    title: "Cupones",
    subtitle: "Canjea tus cupones y obtén descuentos en nuestros productos.",
    apply: "Aplicar",
    add: "Agregar",
    addCoupon: "Añade cupones",
    emptyMessage:
      " Con tus cupones podrás obtener descuentos y muchos más beneficios",
    titleCoupon: "Cupones disponibles para canjear",
    labelTrue: "¿Tienes un cupón?",
    labelFalse: "Ingresa tu cupón"
  }
};

export default es;
