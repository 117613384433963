import useI18N from "contexts/i18N/i18N.hooks";
import { en, es } from "i18n";

const useI18n = () => {
  const { selectedLanguage } = useI18N();
  switch (selectedLanguage) {
    case "en":
      return en;
    case "es":
      return es;
    default:
      return es;
  }
};

export default useI18n;
