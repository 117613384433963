import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const createBusiness = "create your company now";

const en = {
  title: "Simple Commerce",
  createBusiness,
  global: {
    LandingNavbar: {
      dashboard: "Dashboard",
      start: "Log In",
      create: "Create my company",
      howWork: "How it works",
      prices: "Prices",
      alliances: "Alliances"
    }
  },
  home: {
    metaTitle: TITLE,
    metaDescription:
      "Create your company with us and receive the advice you need"
  },
  Business: {
    question: "You have an account?",
    singIn: "Login"
  },
  landingHome: {
    VideoSection: {
      title: "Create your company online and in simple steps",
      description: "We create your company and take care of all the paperwork.",
      button: createBusiness
    },
    InstructionSection: {
      firstTitle:
        "Fill in the detailed information of your company, verify and relax",
      firstDescription:
        "Start your Business is a platform that allows you to create your business smoothly, with the guidance and advice of experts.",
      firstDescriptionParagraph:
        " Having a legal person, that is, a company, allows your business to grow while you and your assets are protected. It gives greater credibility and seriousness in your economic activities and you have exclusive benefits in tax, commercial and labor matters.",
      secondTitle: "We take care of making your goals possible",
      secondDescription:
        "Creating a company is a complex task, you generally require expert corporate lawyers to be able to do it without errors, which implies professional fees, we guide you step by step at a reduced cost so that you can create your company like a professional. ",
      secondDescriptionParagraph:
        "We help you step by step so that your business meets all the requirements established by law.",
      secondDescriptionItems: {
        itemOne: "Faster",
        itemTwo: "You do not incur excessive expenses",
        itemThree: "Advice according to your needs"
      },
      thirdTitle: "Contract our services with the main banking platforms",
      thirdDescription:
        "In one step! As we told you, we take care of everything.",
      thirdDescriptionParagraph: "",
      thirdDescriptionItems: {
        item1: "Produbanco (Visa - MasterCard)",
        item2: "Amazonas (Visa)",
        item3: "Bolivariano (MasterCard)",
        item4: "Mutualista Pichincha (MasterCard)",
        item5: "Internacional (Visa - MasterCard)",
        item6: "Mutualista Azuay (Visa)",
        item7: "Machala (Visa - MasterCard)",
        item8: "Solidario (Visa)"
      }
    },
    ProcessStep: {
      title: "Everything on our platform!",
      subtitle:
        "Create an account, register the name of your business and the corresponding economic activity. Complete the payment form.",
      firstStepTitle: "Complete all company details",
      firstStepInfo:
        "Define the name of your company, or place a temporary name that you can modify later. Enter additional data and continue. Name of your company, economic activity, list of shareholders. Don't worry! It sounds like a lot, but we will do it together, in an easy and guided way.",
      secondStepTitle: "Wait while we take care of it",
      secondStepInfo:
        "In a short time you will have: Your approved company, its unique taxpayer registry, appointments of legal representatives and account.",
      thirdStepTitle: "Clever!",
      thirdStepInfo: "Start billing and grow your business",
      button: createBusiness,
      secondaryButton: "Prices"
    }
  },
  DocumentEmpty: {
    title: "You have no documents available",
    subtitle: "Documents will be displayed here once they are reviewed."
  },
  AdminDocument: {
    successMessage: "The files have been uploaded successfully.",
    successError: "An error occurred. Please, try again.",
    formation: "Formation",
    documents: "Documents",
    sendDocuments: "Send documents",
    taxes: "Taxes",
    uploadFile: "Click or drag the file into this area to upload it",
    uploadFileText: "You can drag one or more files"
  },
  landingsProducts: {
    productFeatures: [
      {
        name: "Reserve your company name."
      },
      {
        name: "Constitutive contract."
      },
      {
        name: "Appointments of legal representatives (2)."
      },
      {
        name: "Advice on the constitutive process."
      },
      {
        name: "Registration and Approval of your company before the Superintendence of Companies."
      },
      {
        name: "Obtaining the RUC (Single Taxpayer Registry)."
      }
    ]
  },
  productsSearch: {
    metaTitle: "Discover"
  },
  loyalty: {
    metaTitle: "Loyalty"
  },
  coupons: {
    metaTitle: "Coupons",
    detailTitle: "Coupons"
  },
  profile: {
    metaTitle: "Profile",
    metaDesciption: "Next you can edit your profile data.",
    discart: "Discart",
    save: "Save"
  },
  CapitalForm: {
    Principal: {
      title: "Capital",
      subtitle:
        "In this section you will design the entire financial scheme of your company",
      shareholderQuestion: "Do you need to add or edit a shareholder?",
      shareholderAction: "Visit the Shareholders page",
      addShareholders: "Add Shareholders",
      shareholdersSubtitle:
        "To complete this tour you must add at least one shareholder"
    },
    financialScheme: {
      title: "Capital of your company.",
      label: "Subscribed Capital"
    },
    title: "Capital of your company.",
    selectLabel: "Term to pay the capital",
    blockTitle: "Define the percentage of shares"
  },
  ShareholdersHelp: {
    title: "Shareholders not updated"
  },
  navigation: {
    shopping: "Shopping",
    discover: "Discover",
    loyalty: "Loyalty",
    coupons: "Coupons",
    profile: "User"
  },
  catalogues: {
    delivery: "Delivery",
    pickup: "Pick-Up",
    other: "Other"
  },
  common: {
    preferences: "Preferences",
    workflows: "Purchase method",
    apply: "Apply",
    buyingIn: "Buying in",
    catalogue: "Catalogue"
  },
  sidebar: {
    itemsCreateCompany: [
      { name: "Personal Info", progress: 1 },
      { name: "Business Info", progress: 2 },
      { name: "Resume and Payment", progress: 3 }
    ],
    success: "Success"
  },
  createCompany: {
    titleBusinessName: "Get started, give your company a name",
    subtitleBusinessName:
      "You can use a temporary name, then you can modify it and add other alternatives",
    placeholderBusinessName: "Company name",
    continue: "Continue",
    optionEnter: "Or press ENTER",
    titleBusinessLocation: "Where will your company be located?",
    subtitleBusinessLocation:
      "Select the country and the city where your company will be located",
    selectCity: "Select a city",
    selectCountry: "Select a country"
  },
  Logo: {
    title: "Arranca tu negocio"
  },
  WelcomeModal: {
    Welcome: "Welcome to "
  },
  Sidebar: {
    dashboard: "Dashboard",
    documents: "Documents",
    partners: "Partners",
    yourBusiness: "Your Business",
    processing: "Processing",
    success: "Success",
    newBusiness: "New Business",
    help: "Help",
    terms: "Terms",
    coupon: "Coupons"
  },
  form: {
    metaTitle: "Profile details",
    addPhoto: "Add photo",
    passwordAuth: "Password & authentication",
    changePass: "Change password",
    forgotPass: "Forgot password",
    actualPass: "Current password",
    newPass: "New password",
    newPassConfirm: "Confirm new password",
    userName: "Username",
    lastName: "Lastname",
    email: "Email",
    phone: "Phone",
    cellphone: "Cellphone"
  },
  CheckoutStatus: {
    title: "Checking that everything is in order",
    text: "We are verifying your payment, this won't take long",
    errorTitle: " There was a problem verifying the payment",
    errorButton: "Try again",
    errorBack: "Back",
    emptyTitle: " You have not added products to your cart yet",
    emptyDescription: "Explore our menu and add products!",
    emptyButton: "Explore Menu"
  },
  loading: {
    title: "Checking that everything is OK",
    subtitle: "We are checking your payment, this will not take too much time"
  },
  mailConfirmation: {
    title: "Check your E-mail",
    titleResend: "We resend the E-mail to ",
    mailFirstPhrase: "Click on the link that we send to the mail",
    mailFirstPhraseResend:
      "Click on the link we sent to your email to access your account.",
    mailSecondPhrase: "to verify your account",
    mailSecondPhraseResend:
      "If you have not received anything in your email, check your SPAM tray, or contact support@atn.com",
    subtitle: "If you have not an e-mail on your inbox or SPAM box you should",
    link: "reapply a new e-mail",
    postOrderlegend: "We have sent an email to",
    postOrdersubtitile:
      "Click on the link we sent to your email to access your account"
  },
  Login: {
    Title: "",
    MailTitle: "Mail",
    Button: "Join"
  },
  SignIn: {
    createBusiness: "Create your Business",
    title: "Hello, join to your account",
    description:
      "Enter your account with your Gmail email, Facebook account or with your email."
  },
  Footer: {
    knowUs: "Know us",
    contactUs: "Contact us",
    workWithUs: "Work with us",
    terms: "Terms",
    help: "Help"
  },
  GeneralInfoForm: {
    title: "General Information",
    subtitle:
      "Add the basic data of your company, such as economic activities, contact, location",
    economicActivity: "Economic Activities",
    descriptionActivity:
      "Enter a brief description of the economic activity they carry out",
    address: "Address",
    country: "Country",
    city: "Select a city",
    street: "Street",
    addressNumber: "Address number",
    secondaryStreet: "Secondary Street",
    example: "Ex: Next to the park",
    question: "How many offices or branches will they have?",
    branches: "Number of branches",
    reference: "Reference",
    mail: "Mail",
    contact: "Contact information",
    fullName: "Full name",
    phone: "Phone",
    cellPhone: "CellPhone",
    state: "State",
    parish: "Parish",
    defData: "Use user default data",
    description: "Description"
  },
  SelectBusiness: {
    title: "Select a business to join",
    new: "Create new Business"
  },
  BusinessNamePage: {
    title: "Reservation of name (company name)",
    subtitle:
      "Write 3 name options for your company, remember to include a unique and unrepeatable word.",
    legalNameTitle: "Tradename",
    legalNamelabel: "tradename",
    legalNamePlaceholder: "Write your tradename",
    commercialName: "Company name or denomination",
    option: "Option",
    commercialNameLabel: "of Business name",
    subLabel:
      "It is the name with which your customers and suppliers will recognize you.",
    knowMore: "Learn More",
    addNew: "Add other business name alternatives",
    chooseExpresion: "Choose a particular expression",
    repetitionError: "WARNING: Avoid repeating the same name suggestion",
    commonWorld: "Warning: Try to not repeat a same word in name suggestions",
    subLabel2:
      "It is the legal name and will be used for all company documentation.",
    suggestions: "Suggestions",
    processing: "Processing",
    checking: "Checking",
    approved: "Approved",
    defData: "Use provisional company name"
  },
  dashboard: {
    completed: "Completed",
    processing: "Processing",
    additionalServices: "Additional Services",
    greeting: "Welcome, ",
    instruction: "You can check the status of your process at the bottom",
    approved: "Passed",
    error: "Needs review",
    incompleted: "Need to complete",
    time: "1 day ago",
    completedInfo: "Complete information",
    unableDescription:
      "You need to complete the steps above to enable this step",
    sendDocuments: "Send Documents",
    sendDocumentsDescription:
      "When you submit all your documents will be saved and cannot be edited.",
    termsModal: {
      title: "We invite you to review the new policies and accept them.",
      firstParagraph:
        "We handle the information you provide to us in order to manage our business transactions and maintain a close relationship with our customers.",
      secondParagraph:
        "We want you to know that you have the right to access, update, revoke, delete, and oppose the processing of your data. If you wish to exercise any of these rights, please do not hesitate to send us an email at: support@client.com. We will be happy to assist you with whatever you need.",
      imAccepted: "I accept the",
      termsAndConditions: "Terms and Conditions",
      and: "&",
      policies: "Privacy Policies",
      legality: "Declaration of Legality and Legality",
      shouldAccept: "You must accept the terms",
      accept: "Accept"
    }
  },
  TermsAndConditions: {
    title: "TERMS AND CONDITIONS",
    firstTerm: "Objeto y alcance de estos términos",
    firstParagraph:
      "Estos Términos y Condiciones rigen los derechos y obligaciones en relación con el uso de los servicios del proveedor ARRANCA TU NEGOCIO S.A.S., con RUC: 1793193524001, en adelante, ”Arranca tu Negocio”, “ATN” o el “proveedor”, con domicilio en Quito D.M., Av. República del Salvador N 1082 y Naciones Unidas.",
    firstParagraphDotTwo:
      "La provisión del servicio consiste en la concesión de derechos de acceso y uso a la plataforma informática propiedad del proveedor a través de internet, en servidores que se encuentran dentro del ámbito de influencia del proveedor del servicio. Al utilizar ATN el usuario podrá introducir datos y recibir asesoría.",
    firstParagraphDotThree:
      "Una condición para el uso sin problemas del servicio es una conexión de internet continua y fiable hasta los servidores del proveedor del servicio. Depende del cliente establecer esta conexión con la ayuda de su dispositivo.",
    firstParagraphDotFour:
      "Arranca tu Negocio no será responsable bajo ninguna circunstancia de la veracidad de la información proporcionada por el usuario, quien se compromete a ingresar en la plataforma únicamente datos fidedignos. Así también Arranca tu Negocio no será responsable del uso y destino de la persona jurídica que a través de su plataforma se cree",
    secondTerm: "Formalización del contrato",
    secondParagraph:
      "Un contrato solo se formaliza al completar correctamente el proceso de registro mediante una confirmación del proveedor del servicio al usuario, en forma escrita por correo electrónico o mediante la prestación del servicio.",
    secondParagraphDotTwo:
      "Para utilizar nuestros servicios, el usuario debe aceptar completamente la Política de Privacidad, la Declaración de Licitud y Legalidad y estos Términos y Condiciones. Al aceptar, acepta que ha leído activamente y comprendido los documentos mencionados.",
    secondParagraphDotThree:
      "El requisito previo para el registro es que el usuario es legalmente competente y tiene la edad mínima de 18 años, es emprendedor o profesional autónomo o propietario de un negocio y utiliza los servicios exclusivamente para uso comercial de fines lícitos y legales.",
    thirthTerm: "Los servicios del proveedor de servicios",
    thirthParagraph:
      "El contenido y el alcance de los servicios se rigen por los acuerdos contractuales respectivos, además, exclusivamente de acuerdo con las funcionalidades del servicio descrito en la celebración del contrato en el sitio web del proveedor del servicio.",
    thirthParagraphDotTwo:
      "El proveedor de servicios proporciona a los usuarios diversos servicios de asesoría legal",
    thirthParagraphDotThree:
      "Los servicios prestados por el proveedor del servicio entre otros, incluyen, en particular, en el área tecnológica:",
    thirthParagraphActivities:
      "Actividades de diseño de la estructura y el contenido de los elementos siguientes (y/o escritura del código informático necesario para su creación y aplicación): programas de sistemas operativos (incluidas actualizaciones y parches de corrección), aplicaciones informáticas (incluidas actualizaciones y parches de corrección), bases de datos y páginas web.",
    thirthParagraphExtraActivities:
      "Actividades de planificación y diseño de sistemas informáticos que integran equipo y programas informáticos y tecnología de las comunicaciones.",
    thirthParagraphAdaptation:
      "Adaptación de programas informáticos a las necesidades de los clientes, es decir, modificación y configuración de una aplicación existente para que pueda funcionar adecuadamente con los sistemas de información de que dispone el cliente.",
    thirthParagraphSuministration:
      "Suministro de infraestructura para servicios de hospedaje, servicios de procesamiento de datos y actividades conexas. Incluye actividades especializadas de hospedaje, como: hospedaje de sitios web, aplicaciones, servicios de transmisión de secuencias de vídeo por internet.",
    thirthParagraphSecondActivity:
      "Actividades de procesamiento y suministro de servicio de registro de datos: elaboración completa de datos facilitados por los clientes, generación de informes especializados a partir de datos facilitados por los clientes.",
    applicationServices: "Servicios de aplicaciones.",
    thirthParagraphDotFour:
      "Solo el usuario respectivo tiene derecho a usar el servicio. La transferencia de la cuenta de usuario a terceros o cualquier otra opción de uso ofrecida por el usuario a terceros quedan expresamente prohibidos.",
    fourthTerm: "Obligaciones de los usuarios",
    fourthParagraph:
      "El usuario está obligado a proporcionar información veraz sobre sí mismo o su empresa, en relación con el uso del servicio.",
    fourthParagraphDotTwo:
      "Al utilizar el servicio, el usuario está obligado a cumplir con las leyes aplicables y a abstenerse de cualquier actividad que afecte o presione excesivamente la operación del servicio o la infraestructura técnica subyacente.",
    fourthParagraphDotThree:
      "El usuario no está autorizado a transmitir sus datos de inicio de sesión a terceros. El usuario está obligado a tratar sus datos de inicio de sesión cuidadosamente y evitar el uso indebido de los datos de inicio de sesión por parte de terceros.",
    fourthParagraphDotFour:
      "El usuario es el único responsable de cumplir con sus obligaciones de conservación. Se asegurará de que sus documentos y datos se mantengan legales, cuando sea necesario, y que las autoridades fiscales tengan el acceso necesario a ellos.",
    fifthTerm: "Aviso sobre el derecho de cancelación",
    fifthParagraph:
      "El proveedor de servicios ofrece sus servicios exclusivamente a personas naturales y empresas.",
    fifthParagraphDotTwo: "No existe el derecho de cancelación.",
    sixthTerm:
      "Precios y condiciones de pago, bloqueo de la cuenta, eliminación de cuenta, ajustes de precios",
    sixthParagraph:
      "El proveedor de servicios ofrece sus servicios en diferentes variantes de pago. Los precios acordados se pueden encontrar en la información de precios y pago vigentes.",
    sixthParagraphDotTwo:
      "El pago de los servicios contratados por el cliente a ATN será pagado mediante tarjeta de crédito.",
    sixthParagraphDotThree: "No se realizará un reembolso.",
    sixthParagraphDotFour:
      "Si los pagos del servicio no se pueden cobrar a tiempo desde la tarjeta de crédito el servicio no será proporcionado, siendo responsabilidad del usuario hacer el pago correspondiente para continuar con el proceso.",
    sixthParagraphDotFive:
      "El usuario acepta que el correo electrónico (proporcionada por el usuario) se podrá utilizará como medio para restablecer su contraseña, enviar comunicaciones, remitir documentos importantes y propios al proceso de constitución de su compañía.",
    seventhTerm: "Garantía y disponibilidad de servicios",
    seventhParagraph:
      "ATN tiene derecho a realizar cambios operativos en el sistema para mejoras sin notificarlo previamente al cliente.",
    eighthTerm: "Derechos de uso",
    eighthParagraph:
      "El usuario tiene derecho a acceder al sitio web para procesar sus datos.",
    eighthParagraphDotTwo:
      "El usuario puede usar Arranca tu Negocio solo para fines comerciales propios y solo para su propio personal.",
    eighthParagraphDotThree:
      "El cliente declara y garantiza que la información del cliente no infringirá los derechos de terceros ni los derechos de propiedad intelectual y no contendrá ningún material que sea obsceno, ofensivo, inapropiado o que infrinja cualquier ley aplicable.",
    ninethTerm: "Privacidad y datos del cliente",
    ninethParagraph:
      "El proveedor del servicio se asegurará de que los datos personales sean recopilados, almacenados y procesados por los usuarios solo en la medida en que sea necesario para la ejecución del contrato y esté permitido por la ley o lo que ordene el legislador. El proveedor del servicio tratará los datos personales de forma confidencial y según las disposiciones de la ley de protección de datos aplicable y no los divulgará a terceros, a menos que esto sea necesario para el cumplimiento de las obligaciones contractuales y/o exista una obligación legal de transmitirlo a terceros.",
    ninethParagraphDotTwo:
      "Con el fin de garantizar el procesamiento auditable de datos, se registra la creación, modificación y eliminación de datos con información del nombre del usuario y la fecha de procesamiento.",
    ninethParagraphDotThree:
      "El uso del servicio puede requerir que el proveedor del servicio procese datos personales en nombre del usuario. Para esto, se requiere la formalización de un acuerdo por separado para el procesamiento de datos personales. Las partes acuerdan que el cliente es el controlador de datos para cualquier información cargada a la aplicación de Arranca tu Negocio y que puede modificar o borrar esta información según sea necesario. Arranca tu Negocio es en todo momento el procesador de datos y procesa los datos en nombre del cliente.",
    ninethParagraphDotFour:
      "Arranca tu Negocio solo procesará los datos del cliente de acuerdo con las instrucciones del cliente y no para un uso propio no autorizado.",
    ninethParagraphDotFive:
      "Arranca tu Negocio mantendrá la confidencialidad de toda la información confidencial del cliente que éste proporcione a Arranca tu Negocio.",
    tenthTerm: "Cambios en los servicios",
    tenthParagraph:
      "El proveedor del servicio ajusta periódicamente sus servicios prestados en internet, a su propia discreción, al desarrollo tecnológico y las necesidades del mercado para cumplir con el uso previsto de acuerdo con la descripción del producto. Esto puede cambiar el contenido del servicio, como una funcionalidad nueva o modificada y adaptaciones a nuevas tecnologías. Dado que estos cambios forman parte de la naturaleza del software, el usuario no puede derivar ningún derecho o reclamo de esto.",
    eleventhTerm: "Límite de responsabilidad",
    eleventhParagraph:
      "Arranca tu Negocio no es responsable de las interrupciones del servicio debido a fuerza mayor, en particular durante una caída o sobrecarga de las redes de comunicaciones mundiales. Por esta razón, el cliente no puede reclamar una reducción de su obligación de servicio.",
    eleventhParagraphDotTwo:
      "Arranca tu Negocio no es responsable de la información publicada sobre sus servicios. El emisor es responsable de su precisión, integridad e intemporalidad.",
    twelfthTerm: "Cambios de los Términos y Condiciones",
    twelfthParagraph:
      "El proveedor del servicio se reserva el derecho de cambiar estos términos y condiciones en cualquier momento con efectividad incluso dentro de las relaciones contractuales existentes, siempre que este cambio, cumpla la normativa."
  },
  privacyAndDataUsagePolicy: {
    title: "POLÍTICA DE PRIVACIDAD Y USO DE DATOS.",
    firstParagraph:
      "ARRANCA TU NEGOCIO S.A.S. (en adelante, también “ATN”), es respetuosa de los datos personales e información que le suministran sus clientes actuales, pasados y potenciales, sus aliados comerciales, sus proveedores, sus visitantes, sus colaboradores, sus transportadores y cualquier otro tercero (en adelante, los Titulares). De acuerdo con lo anterior, en la presente Política de Privacidad (en adelante, la Política), se establecen las finalidades, medidas y procedimientos de nuestras bases de datos, así como los mecanismos con que los Titulares cuentan, para conocer, acceder, rectificar y actualizar, rectificar y actualizar, eliminar, oponerse, portar, suprimir y a no ser objeto de una decisión basada única o parcialmente, en valoraciones automatizadas; los datos suministrados o revocar la autorización que se otorga, con la aceptación de la presente Política.",
    secondParagraph:
      "A los interesados les informamos, que al suministrar sus datos personales con la adquisición de los servicios ofrecidos, la presentación de quejas o reclamos, la celebración de contratos con ARRANCA TU NEGOCIO S.A.S. el ingreso a las instalaciones de ARRANCA TU NEGOCIO S.A.S. y/o la aceptación expresa o inequívoca de acuerdos de confidencialidad y formatos de vinculación; implica la aceptación de los Titulares, de la presente Política y su autorización para los usos y tratamientos de datos que aquí se describen.",
    point1Title: "RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE DATOS.",
    point1Paragraph:
      "El responsable del tratamiento de datos personales y otra información de los Titulares, es la sociedad ARRANCA TU NEGOCIO S.A.S.con domicilio y dirección, Quito D.M., Av. República del Salvador N 1082 y Naciones Unidas. En caso de reclamos, consultas o peticiones sobre datos personales; ARRANCA TU NEGOCIO S.A.S. podrá ser contactado en la dirección indicada o por medio del correo electrónico: soporte.arrancatunegocio@gmail.com o al teléfono: 0983361230.",
    point2Title: "DEFINICIONES",
    point2FirstParagraph:
      "Base de Datos: Conjunto estructurado de datos cualquiera que fuera la forma, modalidad de creación, almacenamiento, organización, tipo de soporte, tratamiento, procesamiento, localización o acceso, centralizado, descentralizado o repartido de forma funcional o geográfica.",
    point2SecondParagraph:
      "Consentimiento: Manifestación de la voluntad libre, específica, informada e inequívoca, por el que los Titulares de los datos personales autorizan a ARRANCA TU NEGOCIO S.A.S. a tratar los mismos, bajo las directrices de la presente Política.",
    point2ThirdParagraph:
      "Dato Personal: Dato que identifica o hace identificable a una persona natural, directa o indirectamente.",
    point2FourthParagraph:
      "Datos Sensibles: Datos relativos a: etnia, identidad de género, identidad cultural, religión, ideología, filiación política, pasado judicial, condición migratoria, orientación sexual, salud, datos biométricos, datos genéticos y aquellos cuyo tratamiento indebido pueda dar origen a discriminación, atenten o puedan atentar contra los derechos y libertades fundamentales.",
    point2FifthParagraph:
      "Datos crediticios: Datos que integran el comportamiento económico de personas naturales, para analizar su capacidad financiera.",
    point2SixthParagraph:
      "Responsable del Tratamiento: persona natural o jurídica, pública o privada, autoridad pública, u otro organismo, que solo o conjuntamente con otros decide sobre la finalidad y el tratamiento de datos personales.",
    point2SeventhParagraph:
      "Titular: Persona natural cuyos datos personales son objeto de tratamiento",
    point2EighthParagraph:
      "Transferencia o comunicación: Manifestación, declaración, entrega, consulta, interconexión, cesión, transmisión, difusión, divulgación o cualquier forma de revelación de datos personales realizada a una persona distinta de los Titulares, responsable o encargado del tratamiento de datos personales.",
    point2NinthParagraph:
      "Tratamiento: Cualquier operación o conjunto de operaciones realizadas sobre datos personales, ya sea por procedimientos técnicos de carácter automatizado, parcialmente automatizado o no automatizado, tales como: la recogida, recopilación, obtención, registro, organización, estructuración, conservación, custodia, adaptación, modificación, eliminación, indexación, extracción, consulta, elaboración, utilización, posesión, aprovechamiento, distribución, cesión, comunicación o transferencia, o cualquier otra forma de habilitación de acceso, cotejo, interconexión, limitación, supresión, destrucción y, en general, cualquier uso de datos personales.",
    point3Title: "FINALIDAD DE LAS BASES DE DATOS",
    point3Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. cuenta con una base de datos, en la se encuentran los datos personales de los Titulares. Las bases de datos de ATN tienen como finalidades principales, las siguientes:",
    point3LetterA:
      "Crear a partir de tales datos una (o más) Sociedad de Acciones Simplificadas.",
    point3LetterB:
      "Crear los formularios necesarios para obtener una cuenta bancaria y/o tarjeta de crédito",
    point3LetterC: "Proveer servicios de contabilidad.",
    point3LetterD: "Ofrecer servicios jurídicos.",
    point3LetterE: "Proveer servicios de marketing digital.",
    point3LetterF:
      "La comunicación con los Titulares para efectos comerciales o de mercadeo.    ",
    point3LetterG: "Responder inquietudes y solventar reclamos.",
    point3LetterH: "El desarrollo de proyectos, productos y concursos",
    point3LetterI:
      "La socialización de políticas, proyectos, programas y cambios organizacionales.",
    point3LetterJ:
      "La realización de análisis estadísticos, comerciales, estratégicos, financieros, sociales, técnicos y calificación de riesgo.",
    point3LetterK:
      "Consultar, reportar y actualizar información comercial, patrimonial o crediticia y financiera en burós de crédito y/o bases de datos con el mismo fin. El tratamiento de estos datos, únicamente será utilizado por ARRANCA TU NEGOCIO S.A.S. para la finalidad de análisis; por lo que, no serán comunicados o difundidos, ni tampoco tendrán una finalidad secundaria.",
    point3LetterL:
      "La implementación de estrategias comerciales, laborales, organizacionales y de mercadeo",
    point3LetterM: "El control y la preservación de la seguridad en ATN",
    point3LetterN:
      "Dar cumplimiento a las obligaciones impuestas por leyes y normas internas de ATN",
    point3LetterO:
      "Cualquier otro requerimiento, generado por la relación comercial y social entre ARRANCA TU NEGOCIO S.A.S. y los Interesados",
    point3SecondParagraph:
      "Este tratamiento está legitimado por el artículo 7, numerales 1, 2, 5 y 8 de la Ley Orgánica de Protección de Datos Personales (en adelante, la LOPDP).",
    point3ThirdParagraph:
      "En el caso de que los Titulares no entreguen sus datos o estos sean erróneos o inexactos, es posible que las finalidades aquí descritas, no puedan ser tratadas por ARRANCA TU NEGOCIO S.A.S. impidiendo el cabal cumplimiento de las mismas o imposibilitando el desarrollo de las actividades descritas en la presente cláusula.",
    point4Title: "FORMA DE OBTENCIÓN Y DATOS SUMINISTRADOS",
    point4Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. podrá pedir expresamente a los Titulares o recolectar de su comportamiento, los datos que sean necesarios para cumplir la finalidad de las Bases de Datos; los cuales podrán ser -entre otros- sus nombres y apellidos, número de identificación (cédula de ciudadanía o de identidad o pasaporte o visas), fecha de nacimiento, dirección de correspondencia, teléfono de contacto, correo electrónico, nombre de su compañía, capital a invertir, capital social, dirección de la compañía, dirección personal, necesidades e intereses, certificado de registro único de contribuyentes, firma electrónica e imágenes en cámaras de vigilancia u otro tipo de formatos. Los datos podrán ser suministrados explícitamente a ARRANCA TU NEGOCIO S.A.S. mediante su plataforma https://arrancatunegocio.com/, mensajería instantánea, correos electrónicos, recolectados personalmente a través de sus empleados, prestadores de servicios, obtenidos mediante consulta a terceros, que administren bases de datos o recolectados implícitamente de las operaciones de análisis de mercado, de grupos objetivo, adquisición de los servicios que son ofrecidos por ARRANCA TU NEGOCIO S.A.S. o de los comportamientos de los Titulares como reclamaciones, solicitudes, encuestas, propuestas, ofertas, visita de las instalaciones, de participación en proyectos, programas y eventos, entre otros.",
    point5Title:
      "TRATAMIENTO DE LOS DATOS PERSONALES ALMACENADOS EN LAS BASES DE DATOS DE ATN",
    point5Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. solo almacenará, usará, procesará y comunicará los datos personales y otra información de los Titulares, para las finalidades descritas y para los tratamientos autorizados por el Titular, en el consentimiento informado, en esta Política o en las leyes vigentes. En adición a lo mencionado en otras cláusulas, los Titulares expresamente, autorizan a ATN para la recolección, uso y circulación de sus datos personales y otra información, para los siguientes propósitos y en las siguientes circunstancias:",
    point5LetterA:
      "Establecer comunicación entre ARRANCA TU NEGOCIO S.A.S. y los Titulares para cualquier propósito relacionado con las finalidades que se establecen en la presente Política, ya sea mediante llamadas, mensajes de texto, correos electrónicos y/o físicos.",
    point5LetterB:
      "Efectuar o implementar la adquisición u oferta de productos o servicios por parte de ATN",
    point5LetterC:
      "Auditar, estudiar y analizar la información de la Base de Datos para diseñar estrategias comerciales y aumentar y/o mejorar los Productos y Servicios que ofrece ATN.",
    point5LetterD:
      "Auditar, estudiar y analizar la información de la Base de Datos para diseñar estrategias de seguridad",
    point5LetterE:
      "Auditar, estudiar, analizar y utilizar la información de la Base de Datos para diseñar, implementar y desarrollar: programas, proyectos y eventos.",
    point5LetterF:
      "Auditar, estudiar, analizar y utilizar la información de la Base de Datos para la socialización de políticas, proyectos, programas, resultados y cambios organizacionales.",
    point5LetterG:
      "Suministrar la información y datos personales de los Titulares a aliados estratégicos para que estos contacten a los Titulares para ofrecerles bienes y servicios de su interés, recibir ofertas de los Titulares, invitar a la participación en programas, proyectos eventos, socializar políticas, proyectos, programas, resultados y cambios organizacionales.",
    point5LetterH:
      "La revelación, cuando la información deba ser entregada a un tercero para cumplir con leyes, regulaciones o procesos legales, para asegurar el cumplimiento de los términos y condiciones, para detener o prevenir fraudes, ataques a la seguridad de ARRANCA TU NEGOCIO S.A.S. o de otros, prevenir problemas técnicos o proteger los derechos de otros como lo requieran los términos y condiciones o la Ley",
    point5SecondParagraph:
      "Este tratamiento está legitimado por el artículo 7, numerales 1, 2, 5 y 8 de la LOPDP.",
    point5ThirdParagraph:
      "No existen otros tratamientos adicionales o ulteriores de los planteados en la presente Política.",
    point6Title: "AUTORIZACIÓN POR CONDUCTA INEQUÍVOCA DEL TITULAR",
    point6Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. podrá realizar el tratamiento de datos personales de Titulares que hayan otorgado su autorización a través de consentimientos informados que permitan concluir de forma razonable, que existe dicha autorización. En este sentido, ARRANCA TU NEGOCIO S.A.S. tratará los datos de los Titulares, en los casos en donde los Titulares contacten directamente a ARRANCA TU NEGOCIO S.A.S.y remitan sus datos personales, para los fines de iniciar una relación comercial, contractual o laboral o para la atención de consultas, reclamos o peticiones, entre otros.",
    point6SecondParagraph:
      "Los Titulares, podrán hacer efectivos sus derechos, en cualquier momento y de las maneras establecidas en esta Política.",
    point7Title: "CAMBIOS EN LA POLÍTICA DE PRIVACIDAD",
    point7Paragraph:
      "Cualquier cambio sustancial en las políticas de tratamiento, será comunicado oportunamente a los Titulares, mediante la publicación en nuestros portales web y/o mediante correo electrónico.",
    point8Title: "ALMACENAMIENTO DE DATOS PERSONALES",
    point8Paragraph:
      "El Titular autoriza expresamente a ARRANCA TU NEGOCIO S.A.S. para que éste, almacene sus datos de la forma que considere más oportuna, siempre que cuente con medidas de seguridad, que permitan la protección de los datos de los Titulares",
    point9Title:
      "MEDIDAS DE SEGURIDAD PARA LA PROTECCIÓN DE LOS DATOS PERSONALES Y OTRA INFORMACIÓN",
    point9Paragraph:
      "Las medidas de seguridad con las que cuenta ARRANCA TU NEGOCIO S.A.S. buscan proteger los datos de los Titulares, en aras de impedir su adulteración, pérdida, usos y accesos no autorizados. Para ello, ARRANCA TU NEGOCIO S.A.S. de forma diligente, implementa medidas de protección humanas, administrativas y técnicas, que razonablemente están a su alcance.",
    point9SecondParagraph:
      "El Titular acepta expresamente, esta forma de protección y declara que la considera conveniente y suficiente, para los propósitos de almacenar y utilizar sus datos personales.",
    point10Title: "DERECHOS DE LOS TITULARES",
    point10Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. informa a los Titulares que, conforme a la legislación vigente, estos tienen los siguientes derechos:",
    point10LetterA: "Derecho a la información",
    point10LetterB: "Derecho de acceso",
    point10LetterC: "Derecho de rectificación y actualización",
    point10LetterD: "Derechos de eliminación",
    point10LetterE: "Derecho de oposición",
    point10LetterF: "Derecho a la portabilidad",
    point10LetterG: "Derecho a la supresión del tratamiento",
    point10LetterH:
      "Derecho a no ser objeto de una decisión basada única o parcialmente en valoraciones automatizadas",
    point10SecondParagraph:
      "ARRANCA TU NEGOCIO S.A.S. se reserva el derecho de seguir tratando los datos de los Titulares, por lo que, no se podrá ejercer los derechos de rectificación, actualización, eliminación, oposición, anulación y portabilidad, en los siguientes casos:",
    point10I:
      "Si los Titulares no son titulares de los datos personales o su representante legal no se encuentre debidamente acreditado;",
    point10II:
      "Cuando los datos personales son necesarios para el cumplimiento de una obligación legal o contractual;",
    point10III:
      "Cuando los datos personales son necesarios para el cumplimiento de una orden judicial, resolución o mandato motivado de autoridad competente;",
    point10IV:
      "Cuando los datos personales son necesarios para la formulación, ejercicio o defensa de reclamos o recursos",
    point10V:
      "Cuando se pueda causar perjuicios a derechos o afectación a intereses legítimos de terceros y ello sea acreditado por ARRANCA TU NEGOCIO S.A.S. al momento de dar respuesta a alguna solicitud, en ejercicio del derecho respectivo por parte de los Titulares;",
    point10VI:
      "Cuando se pueda obstaculizar actuaciones judiciales o administrativas en curso, debidamente notificadas;",
    point10VII:
      "Cuando los datos personales, son necesarios para ejercer el derecho a la libertad de expresión y opinión; y",
    point10ThirdParagraph:
      "En los casos en los que medie el interés público, sujeto al cumplimiento de los estándares internacionales de derechos humanos aplicables a la materia, al cumplimiento de los principios de la LOPDP y a los criterios de legalidad, proporcionalidad y necesidad.",
    point11Title: "TRANSFERENCIAS O COMUNICACIONES DE DATOS",
    point11Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. podrá comunicar los datos personales de los Titulares, a países donde el grupo empresarial ARRANCA TU NEGOCIO S.A.S. tenga operaciones o a socios locales a fin del cumplimiento de la prestación de su servicio o producto.",
    point11SecondParagraph:
      "Posiblemente, algunos de los países a los que se comunicarán los datos personales, no cuenten con normas o estándares de Ecuador; sin embargo, ARRANCA TU NEGOCIO S.A.S. tomará todas las medidas necesarias, de conformidad con la LOPDP, para que los datos personales de los Titulares, estén resguardados.",
    point12Title: "PETICIONES, CONSULTAS, QUEJAS, RECLAMOS Y CONTACTO",
    point12Paragraph:
      "ARRANCA TU NEGOCIO S.A.S. dispone de los medios y recursos para atender y gestionar las peticiones, consultas y reclamos de los Titulares o sus causahabientes para ejercer sus derechos. El medio de comunicación para este fin, es el correo electrónico: o a la dirección de ARRANCA TU NEGOCIO S.A.S. Av. República del Salvador N 1082 y Naciones Unidas, Quito, Ecuador.",
    point12SecondParagraph:
      "Cualquier duda o información adicional, será recibida y tramitada mediante su envío a la dirección física o electrónica de contacto establecidas.",
    point13Title: "PROCEDIMIENTO PARA EJERCER SUS DERECHOS",
    point13Paragraph:
      "En caso de que desee ejercer sus derechos, los Titulares deberán enviar un correo electrónico o físico a las direcciones de contacto establecidas en la presente Política.",
    point13SecondParagraph:
      "En caso de que la solicitud sea presentada por el causahabiente del titular, este deberá demostrar dicha condición, por documento que pruebe la calidad de sucesor de la persona fallecida.",
    point13ThirdParagraph:
      "En caso de que la solicitud sea presentada por el representante o apoderado del titular, este deberá acreditar dicha representación o apoderamiento, a través de documento idóneo y suficiente, como es el poder, la escritura de posesión efectiva o el certificado de nacimiento.",
    point13FourthParagraph:
      "El procedimiento que se seguirá para dichas comunicaciones, será el que se indica a continuación:",
    point13FifthParagraph:
      "Peticiones y Consultas sobre Datos Personales: Cuando el titular de los datos o sus causahabientes, representantes o apoderados deseen consultar la información que reposa en la base de datos, ARRANCA TU NEGOCIO S.A.S. responderá la solicitud, en plazo de máximo quince (15) días hábiles.",
    point13SixthParagraph:
      "Reclamos para la rectificación y actualización, eliminación y oposición de Datos Personales: Cuando el 5 titular de los datos o sus causahabientes, representantes o apoderados, consideren que la información contenida en las bases de datos, debe ser objeto de corrección, actualización o eliminación o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la LOPDP; podrán presentar un reclamo ante ARRANCA TU NEGOCIO S.A.S. el cual será tramitado bajo las siguientes reglas:",
    point13SeventhParagraph:
      "El reclamo se formulará mediante solicitud dirigida a ARRANCA TU NEGOCIO S.A.S. con la identificación de los Titulares, la descripción de los hechos que dan lugar al reclamo, la dirección, y se anexarán los documentos que se quieran hacer valer. ARRANCA TU NEGOCIO S.A.S. atenderá este requerimiento, en el plazo máximo de quince (15) días hábiles. Si el reclamo resulta incompleto, ARRANCA TU NEGOCIO S.A.S. podrá requerir al interesado, dentro de los cinco (5) días calendario, siguientes a la recepción del reclamo, para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo y deberá ingresar uno nuevo, para seguir con el proceso mencionado.",
    point13EighthParagraph:
      "El término máximo para atender el reclamo, será de quince (15) días hábiles, contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al solicitante, los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. La rectificación, actualización, eliminación, oposición, anulación y portabilidad, no procederán cuando se cumpla con alguna de las excepciones descritas en el punto 11 de esta Política.",
    point13NinthParagraph:
      "En caso de que ARRANCA TU NEGOCIO S.A.S.no cumpla con los requerimientos realizados por los Titulares o existiere demoras no justificadas, los Titulares podrán realizar sus reclamos directamente con la Autoridad de Protección de Datos ecuatoriana.",
    point14Title: "PERÍODO DE VIGENCIA DE LA BASE DE DATOS",
    point14Paragraph:
      "Los datos personales incorporados en la Base de Datos, estarán vigentes durante el plazo necesario para cumplir las finalidades para las cuales fueron recolectados; a menos que, el titular contacte a ARRANCA TU NEGOCIO S.A.S. por los medios definidos en el numeral 12 de esta política y ejerza su derecho a la supresión o eliminación de sus datos personales.",
    point14SecondParagraph:
      "En el supuesto de que alguna normativa referente a este punto, establezca plazos determinados, ARRANCA TU NEGOCIO S.A.S. se compromete a tratar los datos personales de los Titulares, hasta el plazo máximo establecido en dicha normativa.",
    point15Title: "LEGISLACIÓN VIGENTE",
    point15Paragraph:
      "La norma que regula la protección de los datos personales en el Ecuador, es la Ley Orgánica de Protección de Datos Personales, su reglamento y las directrices emitidas por la autoridad competente."
  },
  legalityAndLegitimacyStatement: {
    title: "DECLARACIÓN DE LEGALIDAD Y LICITUD",
    firstParagraph:
      "El usuario de Arranca tu Negocio S.A.S. (“ATN” o “Arranca tu Negocio”) acepta el cuestionario que a continuación se coloca, tal y como sigue:",
    firstLine:
      "¿Los accionistas fundadores de esta Sociedad por Acciones Simplificada son personas naturales o jurídicas, hábiles y con capacidad para contratar?",
    secondLine:
      "¿La Sociedad por acciones simplificadas se dedicará a actividades relacionadas con operaciones financieras, de mercado de valores, seguros y otras que tengan un tratamiento especial, de acuerdo con la Ley?",
    thirdLine:
      "¿La información proporcionada durante este proceso de constitución es veraz y auténtica?",
    fourthLine:
      "¿La documentación de soporte presentada durante este proceso de constitución es veraz y auténtica?",
    fifthLine:
      "¿La constitución de esta Sociedad por Acciones Simplificada cumple con todos los requisitos establecidos por la Ley de Compañías?",
    sixthLine:
      "¿Los fondos utilizados en la integración del capital social de esta Sociedad por Acciones Simplificada provienen de actividades ilícitas?",
    seventhLine:
      "¿La Sociedad por Acciones Simplificada tendrá transacciones financieras o negocios comerciales vinculados a actividades ilícitas, tales como, narcotráfico, lavado de dinero o terrorismo?",
    yes: "SI",
    no: "NO"
  },
  PageHelp: {
    title: "Help",
    subtitle:
      "Find a quick response to your concerns; if it were not enough, you can contact support for a personalized guide.",
    faq: "FAQ",
    moreHelp: "Need more help?",
    shareHolder: "Shareholders not updated",
    generalInfo: "Incorrect general information",
    capitalAsigned: "Wrong allocated capital",
    supportContact: "Contact support"
  },
  SignUpUserForm: {
    title: "Just one step more",
    confirm: "Confirm",
    acceptError: "You should accept the terms to continue",
    terms1: "I accept the ",
    terms2: "Terms and conditions",
    and: "&",
    policies: "Privacy Policies",
    legality: "Declaration of Legality and Legality"
  },
  Formation: {
    title: "Formation",
    subtitle: "Here you will find all your important documents.",
    complete: "Complete information",
    fill: "Fill form"
  },
  Shareholders: {
    title: "Shareholders",
    subtitle:
      "Remember that being a shareholder of a company is the same as being its owner.",
    titleCollapse: "Shareholder",
    type: "Type of shareholder",
    identification: "Identification",
    number: "ID number",
    name: "Names / Business name",
    phone: "Landline",
    mobile: "Mobile Phone",
    email: "Mail",
    country: "Country",
    city: "City",
    main: "Main Street",
    numberBusiness: "Business number",
    intersection: "Intersection",
    reference: "Reference",
    add: "Add new shareholder",
    passport: "PASSPORT",
    option_one: "Ecuadorian Natural Person",
    option_two: "Ecuadorian company",
    option_three: "Foreign shareholder",
    representative: "Legal representative",
    delete: "Delete",
    unique: "I am the sole shareholder of the company",
    useLoginDataSwitch: "Use logged in user data",
    uniqueRuleText: "The identification number must be unique."
  },
  Representatives: {
    title: "Legal representatives",
    subtitle:
      "Enter the complete data of the people who will represent and manage your company.",
    titleCollapse: "Legal Representative",
    directionDetail: "Direction Details",
    add: "Add new member",
    assign: "Assign",
    input_one: "Load",
    input_two: "Representation Type",
    input_three: "Focused Person",
    modal_one: {
      title: "Assign the charges",
      subtitle:
        "Positions are the name by which the administrator or legal representative of your company will be known. The type of legal representation you decide to give will define the importance of the position.",
      option_one: "President",
      option_two: "Manager"
    },
    modal_two: {
      title: "Assign representation types",
      subtitle:
        "Legal representation is the ability to represent the company in obligations and rights.",
      option_one: "Owner and surrogate",
      option_two: "Single",
      option_three: "Joint"
    },
    modal_three: {
      title: "Assign the legal representatives",
      subtitle:
        "Positions are the name by which the administrator or legal representative of your company will be known. The type of legal representation you decide to give will define the importance of the position."
    }
  },
  HelpForm: {
    title: "Still need help?",
    additionalComments: "Additional comments",
    addComments: "Add comments",
    sendComments: "Send comments",
    problemNotFixed: "My problem is not fixed",
    dontUnderstand: "I don't understand the steps"
  },
  TipModal: {
    title: "¡Tip!",
    BusinessNamePage: {
      subtitle: " Choose the best name for your company",
      tip1: "Names related to your activity",
      tip1Subtitle:
        "“Marketing company”, “Importing company”, “Flower grower”, “Advice”, “Services...”, “Restaurant”, “Aesthetics”",
      tip2: "Use your first and last names or those of your partners",
      tip2Subtitle:
        "“Pérez y Salazar”, “Terán”, “Ontaneda” “Sol Freile Andrade”",
      tip3: "Do not use special characters",
      tip3Subtitle:
        "Ampersand (&), Comillas (“”), Paréntesis (()), Guión (-), Barra diagonal (/), tilde e inlcusive la letra “ñ”."
    },
    CapitalForm: {
      subtitle:
        "Consider the following tips when defining the shareholders of your company. Remember that being a shareholder of a company is equivalent to being its owner.",
      tip1: "Your spouse cannot be a shareholder",
      tip1Subtitle:
        "You and your spouse cannot be shareholders of the same company. You or your spouse can be a shareholder, never both.",
      tip2: "You can be the only owner!",
      tip2Subtitle:
        "Don't you have more shareholders? Don't worry! You can be the sole shareholder (owner) of your company.",
      tip3: "There is no shareholder limit",
      tip3Subtitle:
        "Do you have several people who will be shareholders? Add the ones you need.",
      titleModal2: "Error",
      tipTitleModal2: "Can't save the shareholders",
      tipSubTitleModal2: "You must add at least one shareholder to continue."
    },
    button: "Got it"
  },
  Terminology: {
    title: "Terminology"
  },
  Documents: {
    title: "Documents",
    subtitle: "Here you will find all your important documents.",
    formation: "Formation",
    taxes: "Taxes",
    download: "Download",
    creationDate: "Creation date"
  },
  SaveDiscardButton: {
    save: "Save",
    discard: "Discard",
    continue: "Save and continue",
    send: "Save and send",
    toolTipNext: "Next Step",
    toolTipBack: "Previous Step"
  },
  BusinessPageHeader: {
    editProfile: "Edit profile",
    logout: "Logout"
  },
  DiscardModal: {
    title: "If you Discard, you will lose all your data",
    subtitle: "If you discard, you could not recover your data",
    discard: "Discard",
    cancel: "Cancel"
  },
  StepWarningModal: {
    title: "Do you want to save the changes before continuing?",
    subtitle:
      "Save your changes before continuing so you don't lose any information",
    discard: "Save and continue",
    cancel: "Cancel and not save"
  },
  ChangeMail: {
    changeMail: "You can then change your email",
    newMail: "New email",
    confirmMail: "Confirm email",
    change: "Change email",
    cancel: "Cancel"
  },
  AdminBusinessHeder: {
    downloadPdf: "Download PDF",
    dowloadExcel: "Download Excel"
  },
  BusinessList: {
    business: "Business",
    sidebar: {
      menu: {
        business: "Companies",
        formation: "Forms"
      },
      items: {
        all: "All",
        done: "Done",
        review: "Need Review"
      }
    }
  },
  BusinessInfoSteps: {
    generalInfo: "General Info",
    shareholders: "Shareholders",
    legalRepresentative: "Legal representative",
    nameBusiness: "Name Reservation",
    capital: "Capital"
  },
  AdminFormHeader: {
    state: "State",
    comment: "Comment",
    update: "Update",
    aproved: "Aproved",
    rejected: "Revision Required",
    addComment: "Add comment",
    commentTitle: "Comment title"
  },
  landingAlliance: {
    AllianceSection: {
      title: "Alliance",
      description:
        "The creation of your company has the support of important companies."
    },
    AllianceList: {
      Trade: {
        title: "Trade",
        subtitle: "Technological capacity",
        description:
          "We are a technology agency that uses data analysis and experience design to create solutions with high transactional capacity."
      },
      Ponce: {
        title: "Ponce Estudio Jurídico",
        subtitle: "Legal security",
        description:
          "We provide specialized legal services in the fundamental areas of law. Tradition and innovation merge at Ponce Estudio Jurídico."
      }
    }
  },
  Country: {
    slogan: "We are expanding constantly",
    title: "Our goal is reach all latin countries",
    subtitle:
      "We seek to continue growing in the Latin American market and help more and more users to create their company completely online."
  },
  BusinessSelect: {
    title: "Select a business to join",
    subtitle: "Select your business",
    description: "Select the company with which you want to enter.",
    logOutQuestion: "Do you want to start with another account?",
    logOut: "Log Out"
  },
  AnonymousEmail: {
    email: "Email",
    emailDescription:
      "The email you use will receive all the information about your company"
  },
  CardHelp: {
    title: "Don't you have a defined name?",
    subtitle:
      "It does not matter if you do not have a defined name, then you can modify it.",
    button: "Got it"
  },
  nameChecker: {
    probabilities: ["...", "Low", "Medium", "High", "Very High"],
    title: "The probability that this name passes is",
    suggestion1: "More than 4 characters",
    suggestion2: "At least 3 words",
    suggestion3: "At least 1 unique word",
    suggestion4: " Do not use special characters "
  },
  Checkout: {
    title: "You are one step away from creating your company",
    subtitle: "Complete your personal data",
    billingTitle: "Billing data",
    myDataCheckout: "My billing information",
    billingAction: "Change",
    billingActionCompaniesNull: "Add",
    methodToPay: "Payment methods",
    emailAccount: "Your account email",
    dataAccount: "Account data",
    isCash: "Payment Link",
    isCard: "Credit Card",
    errorMessageSave: "There was a problem saving the billing data",
    errorMessageUpdate: "There was a problem updating the billing data",
    errorMessagePhone: "Invalid phone number entered",
    address: "Address",
    addAddress: "Add new address",
    selectAddress: "Select an address",
    businessName: "Company name",
    name: "First Name",
    lastName: "Last Name",
    documentType: "ID type",
    documentNumber: "Document number",
    email: "Email",
    phone: "Phone",
    saveAddress: "Use a saved address?",
    default: "Use as default",
    saveBilling: "Save billing data",
    terms1: "I accept the",
    terms2: "terms and conditions",
    and: "&",
    policies: "Privacy Policies",
    legality: "Declaration of Legality and Legality",
    business1: "I accept that my company does not engage in activities such as",
    business2: "transport, security, capital, banking, insurance.",
    business3: "To create this type of company our staff will contact you.",
    company1:
      "I accept and declare that my company will not be a holding company or holder of shares, nor will it be linked through its name to a Holding.",
    errorCheck: "You must accept the fields",
    pay: "Pay Now",
    securePay: "Secure purchase",
    textFooterCard:
      "Make your purchase safe, any concern we offer you the support you need."
  },
  DetailDocuments: {
    title: "Detail of entered data",
    subtitle:
      "Review the information entered. Remember that once the information is sent it cannot be edited.",
    personalInformation: "Personal Information",
    nameReservation: "Name Reservation",
    shareholders: "Shareholders",
    legalRepresentatives: "Legal Representatives",
    percentageOfShares: "Percentage of Shares",
    edit: "Edit",
    back: "Go back",
    sendDocuments: "Send Documents",
    titleModal: "Are you sure you want to send your documents?",
    subtitleModal:
      "Once you submit your documents you will not be able to edit them.",
    confirmText: "Yes, send documents",
    cancelText: "Cancel",
    confirmSend: "Your data has been sent!",
    confirmSubtitle:
      "We will contact you to keep you updated about your new company."
  },
  menuMobile: {
    start: "Start",
    documents: "Documents",
    partners: "Partners",
    profile: "Profile"
  },
  dashboardHide: {
    title: "We are working on the mobile version of your dashboard",
    subtitle: "Login from a desktop computer to review your information.",
    button: "Go home"
  },
  termsLanding: {
    title: "Legal Terms",
    subtitle: "Proposal and legal validation"
  },
  CongratsModal: {
    congrats: "Congratulations!",
    check:
      "Review and fill out all the fields that are pending on the dashboard.",
    button: "Start",
    review:
      "You can now continue with the process of creating your company, while we verify all your data"
  },
  Coupon: {
    title: "Coupon",
    subtitle: "Redeem your coupons and get discounts on our products.",
    apply: "Apply",
    add: "Add",
    addCoupon: "Add Coupons",
    emptyMessage:
      "With you coupons you can get discounts and many more benefits",
    titleCoupon: "Avalible Coupon to exchange",
    labelTrue: "Do you have a Coupon?",
    labelFalse: "Enter your coupon"
  }
};

export default en;
