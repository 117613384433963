import { useContext } from "react";

import { I18NContext } from "./i18N.context";
import { I18NProviderValue } from "./i18N.context.types";

const useI18N = () => {
  const context = useContext<I18NProviderValue>(I18NContext);
  if (typeof context === "undefined") {
    throw new Error("useI18N must be used within a I18NProvider");
  }
  return context;
};

export default useI18N;
